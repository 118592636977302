import { combineReducers } from 'redux';
import userReducer from '../reducers/user';
import restaurantReducer from '../reducers/menu';
import ordersReducer from '../reducers/order';
import configReducer from '../reducers/config';
import versionReducer from '../reducers/version';
import socketReducer from '../reducers/socket';

const reducers = combineReducers({
  user: userReducer,
  restaurant: restaurantReducer,
  orders: ordersReducer,
  config: configReducer,
  version: versionReducer,
  socket: socketReducer,
});

export default reducers;
