// @flow
import {
  Avatar,
  Box,
  CircularProgress,
  Container,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as classNames from 'classnames';
import React, { type Node, useEffect, useState } from 'react';
import Confetti from 'react-dom-confetti';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import uniqid from 'uniqid';
import {
  addOrUpdateItemInOrder,
  checkPromoCode,
  hideOrderSentToRestaurantPopup,
  sendNewItemsToRestaurant,
  sendTableToRestaurant,
  setPaymentMethod,
  setPromoCodeValid,
} from '../actions/order';
import CostSection from '../components/CostSection';
import Header from '../components/Header';
import Icon from '../components/Icon';
import Item from '../components/Item';
import PopupDialog from '../components/PopupDialog';
import RoundButton from '../components/RoundButton';
import WaiterCallPopup from '../components/WaiterCallPopup';
import progress from '../icons/progress.svg';
import promo from '../icons/promoCode.svg';
import { selectors as configSelectors } from '../reducers/config';
import { selectors as menuSelectors } from '../reducers/menu';
import { selectors as ordersSelectors } from '../reducers/order';
import { selectors as userSelectors } from '../reducers/user';
import calculateTotalPrice from '../utils/calculateTotalPrice';
import computeTableOrderState from '../utils/computeTableOrderState';
import formatMoney from '../utils/formatMoney';
import Review from './Review';
import Tips from './Tips';

// TODO: cleanup unused classes if any
const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    position: 'relative',
    height: 250,
    flexShrink: 0,
    flexGrow: 0,
  },
  tabs: {
    paddingTop: 10,
    minHeight: 130,
  },
  noTabs: {
    minHeight: 0,
    maxHeight: 0,
  },
  tab: {
    textTransform: 'capitalize',
    fontSize: 12,
    maxWidth: 125,
  },
  tabContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  photoContainer: {
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
    padding: 2,
    border: '2px solid #151515',
  },
  photo: {
    width: 50,
    height: 50,
  },
  userName: {
    fontWeight: 'bold',
    marginTop: 7,
  },
  state: {
    color: 'lightgrey',
    fontSize: 12,
  },
  order: {
    position: 'relative',
    overflow: 'scroll',
    padding: 20,
  },
  orderState: {
    display: 'flex',
    padding: 20,
    paddingBottom: 0,
    minHeight: 20,
  },
  orderStateText: {
    paddingLeft: 5,
    flexGrow: 1,
  },
  coverPhoto: {
    height: 250,
  },
  ordered: {
    opacity: 0.5,
  },
  title: {
    marginBottom: 20,
  },
  titleSent: {
    marginTop: 30,
    marginBottom: 20,
  },
  numberOfItems: {
    backgroundColor: 'red',
    borderRadius: 30,
    width: 25,
    height: 25,
    textAlign: 'center',
    marginRight: 10,
  },
  textContent: {
    flexGrow: 1,
  },
  comment: {
    fontStyle: 'italic',
    color: '#a8a8a8',
  },
  price: {
    color: 'red',
    fontWeight: 'bold',
  },
  backToMenu: {
    marginTop: 30,
    backgroundColor: 'white',
    border: '1px solid black',
  },
  promo: {
    display: 'flex',
    flexGrow: 1,
  },
  promoModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
  },
  promoText: { paddingLeft: 10, flexGrow: 1 },
  promoIcon: {
    padding: 20,
  },
  promoCodeField: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
  },
  row: {
    display: 'flex',
    marginBottom: 15,
  },
  promoMargin: {
    marginTop: 30,
    marginBottom: 30,
  },
  rowTitle: {
    display: 'flex',
    flexGrow: 1,
  },
  rowValue: {
    display: 'flex',
    fontWeight: 'bold',
  },
  button: {
    width: '100%',
    marginTop: 20,
    fontWeight: 'bold',
  },
  sendOrderDisabled: {
    backgroundColor: 'white',
    border: '1px solid lightgrey',
    color: 'lightgrey',
  },
  paymentTitle: {
    marginTop: 30,
  },
  modalTitle: {
    padding: 20,
    fontWeight: 'bold',
  },
  modalContent: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  modalButtons: {
    position: 'absolute',
    bottom: 20,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalCancel: {
    marginRight: 20,
  },
  modalConfirm: {
    color: 'white',
    margin: 20,
  },
  modalConfirmSmall: {
    color: 'white',
  },
  reviewed: {
    marginTop: 30,
    fontWeight: 'bold',
  },
});

const modalStyles = {
  content: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: 0,
    border: 0,
  },
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const Order = (): Node => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const loading = useSelector(menuSelectors.isLoading);
  const restaurant = useSelector(menuSelectors.restaurant);
  const restaurantId = useSelector(menuSelectors.restaurantId);
  const tableNumber = useSelector(menuSelectors.tableNumber);
  const config = useSelector(configSelectors.config);
  const tableOrder = useSelector(menuSelectors.tableOrder);
  const currentOrder = useSelector(ordersSelectors.currentOrder);
  const order = currentOrder ? currentOrder.get('order') : null;
  const user = useSelector(userSelectors.user);
  const promoCodeValid = useSelector(ordersSelectors.promoCodeValid);
  const showOrderSentToRestaurantPopup = useSelector(
    ordersSelectors.showOrderSentToRestaurantPopup,
  );
  const classes = useStyles();
  const history = useHistory();
  const locale = restaurant ? restaurant.get('country') : null;
  const currency = restaurant ? restaurant.get('currency') : null;
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [promoModalIsOpen, setPromoModalIsOpen] = React.useState(false);
  const [promoCode, setPromoCode] = React.useState('');
  const [showOrderFullTablePopup, setShowOrderFullTablePopup] = React.useState(
    false,
  );

  const [showReviewPopup, setShowReviewPopup] = React.useState(false);

  useEffect(() => {
    if (!order || !restaurantId || !tableOrder) {
      history.push('/');
    }
  });

  const paymentMethodSelected = (paymentMode, order) => {
    if (paymentMode !== order.get('paymentMode')) {
      dispatch(setPaymentMethod(paymentMode, order));
    }
  };

  const sendOrder = (order) => {
    if (order.get('order').find((item) => !item.ordered)) {
      dispatch(sendNewItemsToRestaurant(order, tableOrder));
    }
  };

  const removeItem = (item) => {
    dispatch(addOrUpdateItemInOrder(item, 0, null, null, currentOrder));
  };

  const handleTabSelectChange = (event, tab) => {
    setSelectedTab(tab);
  };

  const returnOrdersInOrder = (orders) => {
    const currentUserOrder = orders.find(
      (order) => order.get('user').id === user.id,
    );
    const ordersInOrder = orders.filter(
      (order) => order.get('user').id !== user.id,
    );
    ordersInOrder.sort(
      (a, b) => b.get('user').get('name') - a.get('user').get('name'),
    );
    ordersInOrder.unshift(currentUserOrder);
    return ordersInOrder;
  };

  const renderTabs = (tableOrder) => {
    const orders = tableOrder.get('orders');
    const ordersInOrder = returnOrdersInOrder(orders);
    return ordersInOrder.map((order, index) => {
      const orderUser = order.get('user');
      return (
        <Tab
          key={orderUser.id}
          icon={renderPhoto(order)}
          className={classes.tab}
          wrapped
          {...a11yProps(index)}
        />
      );
    });
  };

  const renderTabPanels = (tableOrder) => {
    const orders = tableOrder.get('orders');
    const ordersInOrder = returnOrdersInOrder(orders);
    return ordersInOrder.map((order, index) =>
      renderTabPanel(selectedTab, index, order),
    );
  };

  const renderTabPanel = (value, index, orderObject) => {
    const { price, tax, tip, total } = calculateTotalPrice(
      orderObject,
      restaurant,
    );
    const paid = orderObject.get('paid');
    const reviewed = orderObject.get('reviewed');
    const order = orderObject.get('order');
    const isCurrentUser = orderObject.get('user').id === user.id;
    const refunds = orderObject.get('refunds');
    const refundsTotal = refunds
      ? refunds.reduce((acc, refund) => acc + refund.get('amount'), 0)
      : 0;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        key={orderObject.id}
      >
        {value === index && (
          <Box className={classes.order}>
            {!paid
              ? order.find((order) => order.state === 'pending') && (
                  <Typography variant="h6" className={classes.title}>
                    {t('containers.Order.pending')}
                  </Typography>
                )
              : null}
            {!paid
              ? order
                  .filter((order) => order.state === 'pending')
                  .map((order) => (
                    <Item
                      item={order}
                      key={uniqid()}
                      removable
                      removeAction={() => removeItem(order.item)}
                      locale={locale}
                      currency={currency}
                    />
                  ))
              : null}
            {isCurrentUser && !paid ? (
              <RoundButton
                className={classes.backToMenu}
                fullWidth
                onClick={() => history.push('/menu')}
              >
                {!currentOrder
                  .get('order')
                  .find((order) => order.state === 'pending')
                  ? t('containers.Order.orderNewItems')
                  : t('containers.Order.addOrRemoveItems')}
              </RoundButton>
            ) : null}
            {isCurrentUser &&
              !paid &&
              currentOrder
                .get('order')
                .find((order) => order.state === 'pending') && (
                <RoundButton
                  className={classes.button}
                  fullWidth
                  color="primary"
                  onClick={() => sendOrder(orderObject)}
                  disabled={
                    currentOrder
                      .get('order')
                      .filter((order) => order.state === 'pending').length === 0
                  }
                >
                  {t('containers.Order.confirm')}
                </RoundButton>
              )}
            {isCurrentUser &&
            orderObject.get('state') === 'ordered' &&
            !currentOrder
              .get('order')
              .find((order) => order.state === 'pending') &&
            tableOrder.get('state') === 'pending' ? (
              <RoundButton
                className={classes.button}
                fullWidth
                color="primary"
                onClick={() => setShowOrderFullTablePopup(true)}
              >
                {t('containers.Order.validate')}
              </RoundButton>
            ) : null}
            {order.find((order) => order.state !== 'pending') && !paid && (
              <Typography variant="h6" className={classes.titleSent}>
                {t('containers.Order.confirmed')}
              </Typography>
            )}
            {order
              .filter((order) => order.state !== 'pending')
              .map((item) => (
                <Item
                  item={item}
                  key={uniqid()}
                  locale={locale}
                  currency={currency}
                />
              ))}
            {refunds && refunds.length > 0 && (
              <>
                <Typography
                  variant="h6"
                  className={classes.title}
                  style={{ marginTop: '20px' }}
                >
                  {t('containers.Order.refunds')}
                </Typography>
                {refunds.map((refund) => (
                  <Box className={classes.row} key={refund.id}>
                    <Box className={classes.promo}>
                      <Typography className={classes.promoText}>
                        {refund.get('reason')}
                      </Typography>
                    </Box>
                    <Typography className={classes.rowValue}>
                      {`-${refund.get('amount')}$`}
                    </Typography>
                  </Box>
                ))}
              </>
            )}
            {renderPromo(orderObject)}
            {renderPromoModal(orderObject)}
            <CostSection
              price={price}
              refundsTotal={refundsTotal}
              tax={tax}
              tip={tip}
              total={total}
              orderObject={orderObject}
              paid={paid}
              paymentMethodSelected={paymentMethodSelected}
              order={order}
              isCurrentUser={isCurrentUser}
              user={user}
              onClickTip={() => setModalIsOpen(index)}
              tableNumber={tableNumber}
              restaurant={restaurant}
              tableOrder={tableOrder}
            />

            {isCurrentUser && paid && !reviewed && (
              <>
                <RoundButton
                  className={classes.button}
                  fullWidth
                  color="primary"
                  onClick={() => setShowReviewPopup(true)}
                >
                  {t('containers.Order.review')}
                </RoundButton>
                <Review
                  order={orderObject}
                  isOpen={showReviewPopup}
                  setIsOpen={setShowReviewPopup}
                />
              </>
            )}
            {isCurrentUser && reviewed && (
              <Typography className={classes.reviewed}>
                {t('containers.Order.reviewed')}
              </Typography>
            )}
            <WaiterCallPopup />
            <PopupDialog isOpen={showOrderFullTablePopup} height={300}>
              <Typography className={classes.modalTitle}>
                {t('common.sure')}
              </Typography>
              <Typography className={classes.modalContent}>
                {t('containers.Order.pleaseCheck')}
              </Typography>
              <Box className={classes.modalButtons}>
                <RoundButton
                  className={classes.modalCancel}
                  onClick={() => setShowOrderFullTablePopup(false)}
                >
                  {t('common.cancel')}
                </RoundButton>
                <RoundButton
                  className={classes.modalConfirmSmall}
                  color="primary"
                  onClick={() => {
                    setShowOrderFullTablePopup(false);
                    dispatch(sendTableToRestaurant(tableOrder));
                  }}
                >
                  {t('common.confirm')}
                </RoundButton>
              </Box>
            </PopupDialog>
          </Box>
        )}
        <Tips
          price={price}
          orderTip={orderObject.get('tip') * 100}
          modalIsOpen={modalIsOpen === index}
          tips={config.attributes.tip}
          closeModal={() => setModalIsOpen(false)}
          order={orderObject}
          restaurant={restaurant}
        />
        <Confetti active={paid} />
        <Confetti active={reviewed} />
      </div>
    );
  };

  const renderIcon = (svg, small) => <Icon svg={svg} small={small} />;

  const renderPromo = (orderObject) => {
    const discount = orderObject.get('discountPointer');
    const paid = orderObject.get('paid');
    if (
      (paid && orderObject.get('user').id !== user.id) ||
      (paid && !discount)
    ) {
      return null;
    }
    return (
      <Box className={classNames(classes.row, classes.promoMargin)}>
        <Box
          className={classes.promo}
          onClick={() => {
            if (!paid) setPromoModalIsOpen(true);
          }}
        >
          {renderIcon(promo)}
          <Typography className={classes.promoText}>
            {discount
              ? t('containers.Order.promoCode', { code: discount.get('info') })
              : t('containers.Order.promoButton')}
          </Typography>
        </Box>
        {discount && discount.get('discount') && (
          <Typography className={classes.rowValue}>
            -{formatMoney(discount.get('discount'), locale, currency)}
          </Typography>
        )}
      </Box>
    );
  };

  const renderPromoModal = (orderObject) => (
    <Modal isOpen={promoModalIsOpen} style={modalStyles}>
      <Box className={classes.promoModal}>
        <Box className={classes.promoIcon}>{renderIcon(promo)}</Box>
        <TextField
          id="outlined-basic"
          label={t('containers.Order.promo')}
          variant="outlined"
          className={classes.promoCodeField}
          value={promoCode}
          onChange={(event) => {
            setPromoCode(event.target.value);
          }}
        />
        <RoundButton
          className={classes.button}
          fullWidth
          color="primary"
          onClick={() => {
            if (promoCode.length > 0)
              dispatch(
                checkPromoCode(promoCode, orderObject.get('user'), orderObject),
              );
          }}
        >
          {t('containers.Order.promoButton')}
        </RoundButton>
        <RoundButton
          className={classes.button}
          fullWidth
          onClick={() => {
            setPromoModalIsOpen(false);
            setPromoCode('');
          }}
        >
          {t('common.cancel')}
        </RoundButton>
        <PopupDialog isOpen={promoCodeValid === false} height={220}>
          <Typography className={classes.modalTitle}>
            {t('containers.Order.promoModalFailTitle')}
          </Typography>
          <Typography className={classes.modalContent}>
            {t('containers.Order.promoModalFailContent')}
          </Typography>
          <RoundButton
            className={classes.modalConfirm}
            color="primary"
            onClick={() => {
              setPromoCode('');
              dispatch(setPromoCodeValid(null));
            }}
          >
            {t('common.ok')}
          </RoundButton>
        </PopupDialog>
        <PopupDialog isOpen={promoCodeValid === true} height={220}>
          <Typography className={classes.modalTitle}>
            {t('containers.Order.promoModalSuccessTitle')}
          </Typography>
          <Typography className={classes.modalContent}>
            {t('containers.Order.promoModalSuccessContent')}
          </Typography>
          <RoundButton
            className={classes.modalConfirm}
            color="primary"
            onClick={() => {
              setPromoCode('');
              dispatch(setPromoCodeValid(null));
              setPromoModalIsOpen(false);
            }}
          >
            {t('common.ok')}
          </RoundButton>
        </PopupDialog>
      </Box>
    </Modal>
  );

  const renderPhoto = (order) => {
    const orderUser = order.get('user');
    const isCurrentUser = orderUser.id === user.id;
    const profilePictureUrl = orderUser.get('profilePicture')
      ? orderUser.get('profilePicture').url()
      : null;
    return (
      <Box className={classes.tabContent}>
        <Box className={classes.photoContainer}>
          {profilePictureUrl && (
            <Avatar
              src={orderUser.get('profilePicture').url()}
              className={classes.photo}
            />
          )}
        </Box>
        <Typography className={classes.userName}>
          {isCurrentUser ? t('common.me') : orderUser.get('name').split(' ')[0]}
        </Typography>
        <Typography className={classes.state}>
          {order.get('paid')
            ? t('containers.Order.paid')
            : order.get('state') === 'ordered'
            ? t('containers.Order.ready')
            : t('containers.Order.ordering')}
        </Typography>
      </Box>
    );
  };

  const renderOrderSentToRestaurantPopup = () => (
    <PopupDialog isOpen={showOrderSentToRestaurantPopup} height={270}>
      <Typography className={classes.modalTitle}>
        {t('containers.Order.confirmationModalTitle')}
      </Typography>
      <Typography className={classes.modalContent}>
        {restaurant.get('currentWaitTime') === (0 || undefined) &&
          t('containers.Order.confirmationModalContentNoWait')}
        {restaurant.get('currentWaitTime') > 0 &&
          t('containers.Order.confirmationModalContent')}
        {restaurant.get('currentWaitTime') > 0 &&
          t('containers.Order.confirmationModalWaitTime', {
            time: Number(restaurant.get('currentWaitTime').toFixed(0)),
          })}
      </Typography>
      <Box className={classes.modalButtons}>
        <RoundButton
          className={classes.modalConfirm}
          color="primary"
          onClick={() => dispatch(hideOrderSentToRestaurantPopup())}
        >
          {t('common.ok')}
        </RoundButton>
      </Box>
    </PopupDialog>
  );

  const showTabs =
    tableOrder &&
    !(tableOrder.get('minGuests') === 1 && tableOrder.get('orders').length < 2);

  return (
    <>
      {loading || !restaurant || !order || !tableOrder || !config ? (
        <Container className={classes.loadingContainer}>
          <CircularProgress />
        </Container>
      ) : (
        <Container className={classes.container}>
          <Header restaurant={restaurant} order={currentOrder} />
          {showTabs && (
            <Box className={classes.orderState}>
              {renderIcon(progress)}
              <Box className={classes.orderStateText}>
                <Typography color="primary" variant="h6">
                  {computeTableOrderState(tableOrder)}
                </Typography>
              </Box>
              <Box>
                <Typography color="primary" variant="h6">
                  {t('containers.Order.ordered', {
                    ordered: tableOrder
                      .get('orders')
                      .filter((order) => order.get('state') === 'ordered')
                      .length,
                    guests: Math.max(
                      tableOrder.get('orders').length,
                      tableOrder.get('minGuests'),
                    ),
                  })}
                </Typography>
              </Box>
            </Box>
          )}
          <Tabs
            value={selectedTab}
            onChange={handleTabSelectChange}
            variant="scrollable"
            indicatorColor="primary"
            scrollButtons="auto"
            aria-label="scrollable auto tabs"
            className={showTabs ? classes.tabs : classes.noTabs}
          >
            {renderTabs(tableOrder)}
          </Tabs>
          {renderTabPanels(tableOrder)}
          {renderOrderSentToRestaurantPopup()}
        </Container>
      )}
    </>
  );
};
export default Order;
