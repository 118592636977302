import { eventChannel } from 'redux-saga';

export default function createLiveQueryChannel(
  subscription,
  createAction,
  updateAction,
  deleteAction,
  fetchAction,
  name,
) {
  let isSubscriptionClosed = false;
  console.info('---------------');
  console.info(`${name} > subscribed`);
  return eventChannel((emitter) => {
    // Run on "create" event
    subscription.on('create', (object) => {
      if (createAction) {
        console.info('---------------');
        console.info(`${name} > create`, object);
        emitter(createAction(object));
      }
    });
    // Run on "update" event
    subscription.on('update', (object) => {
      if (updateAction) {
        console.info('---------------');
        console.info(`${name} > update`, object);
        emitter(updateAction(object));
      }
    });
    // Run on "delete" event
    subscription.on('delete', (object) => {
      if (deleteAction) {
        console.info('---------------');
        console.info(`${name} > delete`, object);
        emitter(deleteAction(object));
      }
    });

    subscription.on('open', () => {
      console.info('---------------');
      console.info(`${name} > open`);
      if (isSubscriptionClosed) {
        if (fetchAction) emitter(fetchAction());
        isSubscriptionClosed = false;
      }
    });

    subscription.on('close', () => {
      console.info('---------------');
      console.info(`${name} > close`);
      isSubscriptionClosed = true;
    });

    // Run when channel is closed
    return () => {
      subscription.unsubscribe().then(() => {
        console.info('---------------');
        console.info(`${name} > unsubscribed`);
        isSubscriptionClosed = false;
      });
    };
  });
}
