export default function calculateTotalPrice(currentOrder, restaurant) {
  const order = currentOrder ? currentOrder.get('order') : null;
  const price = order
    ? order.reduce((accumulator, current) => {
        const orderPrice = current.item.price - (current.item.deal || 0);
        return accumulator + orderPrice * current.number;
      }, 0)
    : 0;

  const refunds = currentOrder.get('refunds');
  const refundsTotal = refunds
    ? refunds.reduce((acc, refund) => acc + refund.get('amount'), 0)
    : 0;

  const tax =
    price && restaurant ? (price - refundsTotal) * restaurant.get('tax') : 0;

  const tip = price ? (price - refundsTotal) * currentOrder.get('tip') : 0;
  const discountObject = currentOrder.get('discountPointer');
  const discountValue = discountObject ? discountObject.get('discount') : 0;
  const totalTemp = price + tax + tip - discountValue - refundsTotal;
  const total = totalTemp > 0 ? totalTemp : 0;

  return { price, tax, tip, total };
}
