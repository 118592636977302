import Parse from 'parse';
import * as Sentry from '@sentry/browser';
import { put, takeLatest } from 'redux-saga/effects';
import {
  ACTIONS,
  fetchConfigError,
  fetchConfigSuccess,
} from '../actions/config';

function* requestConfigSaga() {
  try {
    const config = yield Parse.Config.get();
    yield put(fetchConfigSuccess(config));
  } catch (error) {
    yield put(fetchConfigError(error));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.info('requestConfigSaga error', error);
  }
}

export default function* () {
  yield takeLatest(ACTIONS.REQUEST_CONFIG, requestConfigSaga);
}
