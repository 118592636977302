import i18next from 'i18next';

const computeTableOrderState = (tableOrder) => {
  const state = tableOrder.get('state');
  if (state === 'progress') {
    return i18next.t('utils.computeTableOrderState.progress');
  } else if (state === 'completed') {
    return i18next.t('utils.computeTableOrderState.completed');
  } else if (state === 'closed') {
    return i18next.t('utils.computeTableOrderState.closed');
  } else {
    return i18next.t('utils.computeTableOrderState.pending');
  }
};

export default computeTableOrderState;
