// @flow
import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import i18next from 'i18next';
import React, { type Node, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { fetchVersion, openVersionSocket } from '../actions/version';
import { selectors as socketSelectors } from '../reducers/socket';
import { selectors as versionSelectors } from '../reducers/version';
import Terms from './Terms';
import SignInPage from './SignInPage';
import SignUpPage from './SignUpPage';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    background: 'white',
    color: theme.palette.primary.main,
  },
  title: {
    marginBottom: 20,
  },
}));

const HomeSwitch = (): Node => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const versionSocketLoading = useSelector(
    socketSelectors.versionSocketLoading,
  );
  const versionSocketOpened = useSelector(socketSelectors.versionSocketOpened);
  const version = useSelector(versionSelectors.version);
  const errorVersion = useSelector(versionSelectors.errorVersion);

  // We don't want stale code to send requests to the backend
  useEffect(() => {
    dispatch(fetchVersion());
  }, [dispatch]);
  useEffect(() => {
    if (!versionSocketLoading && !versionSocketOpened) {
      dispatch(openVersionSocket());
    }
  }, [versionSocketLoading, versionSocketOpened, dispatch]);

  useEffect(() => {
    if (errorVersion) {
      addToast(i18next.t('toasts.error'), { appearance: 'error' });
    }
  }, [errorVersion]);

  // If the code is stale, we notify and lock the UI
  const versionNumber = version ? version.get('productionVersion') : null;
  if (process.env.REACT_APP_VERSION !== versionNumber) {
    return (
      <Container className={classes.container}>
        <Typography variant="h5" className={classes.title}>
          {t('containers.Refresh.title')}
        </Typography>
        <Typography>{t('containers.Refresh.content')}</Typography>
        <br />
        <Typography>{process.env.REACT_APP_VERSION}</Typography>
      </Container>
    );
  }

  return (
    <Switch>
      <Route path="/" component={SignInPage} exact />
      <Route path="/signUp" component={SignUpPage} />
      <Route path="/signIn" component={SignInPage} />
      <Route path="/terms" component={Terms} />
      <Route exact path="/:restaurantIdAndTable" component={SignInPage} />
      <Redirect from="*" to="/" />
    </Switch>
  );
};

export default HomeSwitch;
