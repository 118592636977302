import produce from 'immer';
import { ACTIONS as MENU_ACTIONS } from '../actions/menu';
import { ACTIONS } from '../actions/order';
import { ACTIONS as USER_ACTIONS } from '../actions/user';
import Parse from 'parse';

const initialState = {
  currentOrder: null,
  orders: null,
  errorLoadingOrders: null,
  error: null,
  promoCodeValid: null,
  paymentInProcess: null,
  paymentError: null,
  showOrderSentToRestaurantPopup: false,
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case MENU_ACTIONS.UPDATE_CURRENT_ORDER:
      draft.currentOrder = payload;
      break;
    case MENU_ACTIONS.TABLE_ORDER_SUCCESS:
      draft.currentOrder = payload.tableOrder
        .get('orders')
        .find((order) => order.get('user').id === Parse.User.current().id);
      break;
    case ACTIONS.FETCH_ALL_CURRENT_USER_ORDERS_SUCCESS:
      draft.error = null;
      draft.orders = payload.orders;
      break;
    case ACTIONS.FETCH_ALL_CURRENT_USER_ORDERS_ERROR:
      draft.error = null;
      draft.errorLoadingOrders = payload.error;
      break;
    case ACTIONS.SET_PROMO_CODE_VALID:
      draft.error = null;
      draft.promoCodeValid = payload;
      break;
    case ACTIONS.PAYMENT_IN_PROCESS:
      draft.error = null;
      draft.paymentInProcess = true;
      draft.paymentError = null;
      break;
    case ACTIONS.PAYMENT_SUCCESS:
      draft.error = null;
      draft.paymentInProcess = false;
      break;
    case ACTIONS.PAYMENT_ERROR:
      draft.error = null;
      draft.paymentInProcess = false;
      draft.paymentError = payload.error;
      break;
    case ACTIONS.ORDER_SENT_TO_RESTAURANT:
      draft.error = null;
      draft.showOrderSentToRestaurantPopup = true;
      break;
    case ACTIONS.HIDE_ORDER_SENT_TO_RESTAURANT_POPUP:
      draft.error = null;
      draft.showOrderSentToRestaurantPopup = false;
      break;
    case ACTIONS.ORDER_ERROR:
      draft.error = payload.error;
      break;
    case ACTIONS.ADD_OR_UPDATE_ITEM_IN_ORDER:
    case ACTIONS.SEND_NEW_ITEMS_TO_RESTAURANT:
    case ACTIONS.SEND_TABLE_TO_RESTAURANT:
    case ACTIONS.UPDATE_PAYMENT_METHOD:
    case ACTIONS.UPDATE_TIP:
      draft.error = null;
      break;
    case USER_ACTIONS.LOGOUT:
    case MENU_ACTIONS.SWITCH_RESTAURANT:
      draft.currentOrder = null;
      break;
    default:
      break;
  }
}, initialState);

export default reducer;

export const selectors = {
  currentOrder: (state) => state.orders.currentOrder,
  orders: (state) => state.orders.orders,
  promoCodeValid: (state) => state.orders.promoCodeValid,
  paymentInProcess: (state) => state.orders.paymentInProcess,
  showOrderSentToRestaurantPopup: (state) =>
    state.orders.showOrderSentToRestaurantPopup,
  errorLoadingOrders: (state) => state.orders.errorLoadingOrders,
  paymentError: (state) => state.orders.paymentError,
  error: (state) => state.orders.error,
};
