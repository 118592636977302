// @flow
import { Box, CircularProgress, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { type Node, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import uniqid from 'uniqid';
import {
  fetchOrders,
  openAllUserOrdersSocket,
  sendNewItemsToRestaurant,
} from '../actions/order';
import CostSection from '../components/CostSection';
import Header from '../components/Header';
import Item from '../components/Item';

import {
  selectors as orderSelectors,
  selectors as ordersSelectors,
} from '../reducers/order';
import { selectors as userSelectors } from '../reducers/user';
import calculateTotalPrice from '../utils/calculateTotalPrice';

const useStyles = makeStyles({
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    padding: 0,
  },
  orderContainer: {
    padding: 20,
  },
  items: {
    marginBottom: 20,
  },
});

const OldOrder = (): Node => {
  const { orderId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector(userSelectors.user);
  const orders = useSelector(orderSelectors.orders);
  const currentOrder = useSelector(ordersSelectors.currentOrder);
  const allUserOrdersSocketLoading = useSelector(
    ordersSelectors.allUserOrdersSocketLoading,
  );
  const allUserOrdersSocketOpened = useSelector(
    ordersSelectors.allUserOrdersSocketOpened,
  );

  // If user wants to see an open order, we direct to the Order container
  useEffect(() => {
    if (currentOrder && currentOrder.id === orderId) {
      history.push('/order');
    }
  }, [orderId, currentOrder]);

  useEffect(() => {
    dispatch(fetchOrders(user.id));
  }, []);

  // Subscribe to changes to all the user's existing orders, stays alive until logout
  useEffect(() => {
    if (!allUserOrdersSocketLoading && !allUserOrdersSocketOpened) {
      dispatch(openAllUserOrdersSocket());
    }
  }, []);

  const orderObject = orders
    ? orders.find((order) => order.id === orderId)
    : null;

  const refunds = orderObject ? orderObject.get('refunds') : [];
  const refundsTotal = refunds
    ? refunds.reduce((acc, refund) => acc + refund.get('amount'), 0)
    : 0;
  const sendOrder = () => {
    if (orderObject.get('order').find((item) => item.state === 'pending')) {
      dispatch(
        sendNewItemsToRestaurant(orderObject, orderObject.get('tableOrder')),
      );
    }
  };
  const renderOrder = (order) => {
    const restaurant = orderObject.get('restaurant');
    const locale = restaurant.get('country');
    const currency = restaurant.get('currency');
    const { price, tax, tip, total } = calculateTotalPrice(
      orderObject,
      restaurant,
    );
    return (
      <Box className={classes.orderContainer}>
        <Box className={classes.items}>
          {order.map((item) => (
            <Item
              item={item}
              key={uniqid()}
              locale={locale}
              currency={currency}
            />
          ))}
        </Box>
        <CostSection
          price={price}
          tax={tax}
          tip={tip}
          total={total}
          orderObject={orderObject}
          paid={orderObject.get('paid')}
          order={order}
          isCurrentUser={true}
          user={user}
          tableNumber={orderObject.get('tableNumber')}
          restaurant={restaurant}
          sendOrder={sendOrder}
          refundsTotal={refundsTotal}
        />
      </Box>
    );
  };

  return (
    <>
      {!orders ? (
        <Container className={classes.loadingContainer}>
          <CircularProgress />
        </Container>
      ) : (
        <Container className={classes.container}>
          <Header
            restaurant={orderObject.get('restaurant')}
            order={orderObject}
            oldOrder
          />
          {renderOrder(orderObject.get('order'))}
        </Container>
      )}
    </>
  );
};

export default OldOrder;
