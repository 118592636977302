// @flow
import React, { type Node } from 'react';
import { Box } from '@material-ui/core';
import * as classNames from 'classnames';
import MaterialIcon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  iconRoot: {
    textAlign: 'center',
  },
  imageIcon: {
    height: '100%',
  },
  iconContainer: {
    height: 25,
  },
  smallIcon: {
    height: 15,
    marginLeft: 10,
    marginTop: -3,
  },
});

type Props = {
  svg: Object,
  small?: number,
  styles?: Object,
  onClick?: Function,
};

const Icon = ({ svg, small, styles, onClick }: Props): Node => {
  const classes = useStyles();
  return (
    <Box
      className={classNames(
        classes.iconContainer,
        small ? classes.smallIcon : null,
        styles,
      )}
    >
      <MaterialIcon classes={{ root: classes.iconRoot }} onClick={onClick}>
        <img className={classes.imageIcon} src={svg} alt="icon" />
      </MaterialIcon>
    </Box>
  );
};

export default Icon;
