import { CircularProgress, MuiThemeProvider } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import i18next from 'i18next';
import Parse from 'parse';
import React, { useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { setCurrentUser } from './actions/user';
import HomeSwitch from './containers/HomeSwitch';
import LoggedSwitch from './containers/LoggedSwitch';
import './locales/i18n';
import { selectors as userSelectors } from './reducers/user';
import theme from './theme';

Parse.initialize(
  '7ei5pxppEe8ErCMB6Ehm6HTIJRcdJJd7SRHUpN8n',
  'r9orWceUpg06alGZA4j5zl3JnpRIMNReMN5cH78J',
);
Parse.serverURL =
  'https://pg-app-jtxlimrcv63uszqdkzh2a9d1r7pd0u.scalabl.cloud/1/';
// Parse.serverURL = 'http://localhost:1337/1/';

// Load the Facebook API asynchronous when the window starts loading
window.fbAsyncInit = function () {
  Parse.FacebookUtils.init({
    appId: '321736442526427', // Facebook App ID
    cookie: true, // enable cookies to allow Parse to access the session
    xfbml: true, // initialize Facebook social plugins on the page
    version: 'v10.0', // point to the latest Facebook Graph API version, found in FB's App dashboard.
  });
};

// Include the Facebook SDK
(function (d, s, id) {
  const fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {
    return;
  }
  const js = d.createElement(s);
  js.id = id;
  js.src = 'https://connect.facebook.net/en_US/sdk.js';
  fjs.parentNode.insertBefore(js, fjs);
})(document, 'script', 'facebook-jssdk');

// Send error logs to Sentry
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://509d80beca7a4301a969f1ec3b44a438@o19398.ingest.sentry.io/5500620',
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

const App = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const isLogged = useSelector(userSelectors.isLogged);

  useEffect(() => {
    dispatch(setCurrentUser(Parse.User.current()));
    setLoading(false);
  }, [dispatch]);

  return (
    <MuiThemeProvider theme={theme}>
      <I18nextProvider i18n={i18next}>
        <ToastProvider autoDismiss>
          <BrowserRouter>
            {loading ? (
              <CircularProgress />
            ) : isLogged ? (
              <LoggedSwitch />
            ) : (
              <HomeSwitch />
            )}
          </BrowserRouter>
        </ToastProvider>
      </I18nextProvider>
    </MuiThemeProvider>
  );
};

export default App;
