// @flow
import React, { type Node } from 'react';
import { Formik, Form } from 'formik';
import {
  Box,
  Container,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loginFB, signUp } from '../actions/user';
import { selectors as userSelectors } from '../reducers/user';
import TextInputFormik from '../components/TextInputFormik';
import RoundButton from '../components/RoundButton';

const SignInPage = (): Node => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoading = useSelector(userSelectors.isLoading);
  const error = useSelector(userSelectors.error);
  const history = useHistory();
  const { t } = useTranslation();

  const textFieldStyle = {
    fontSize: 14,
    color: '#151515',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
  };

  return (
    <>
      <Container style={{ padding: 20, marginTop: 30 }}>
        <Formik
          initialValues={{ name: '', email: '', password: '' }}
          onSubmit={(values) => {
            dispatch(signUp(values.name, values.email, values.password));
          }}
          validate={(values) => {
            const errors = {};

            if (!values.name) {
              errors.name = t('common.required');
            }

            if (!values.email) {
              errors.email = t('common.required');
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
            ) {
              errors.email = t('common.invalidEmail');
            }

            if (!values.password) {
              errors.password = t('common.required');
            }
            return errors;
          }}
        >
          {() => (
            <Form>
              <TextInputFormik
                name="name"
                className={classes.fields}
                textStyle={textFieldStyle}
                placeholder={t('common.name')}
                fullWidth
              />
              <TextInputFormik
                name="email"
                className={classes.fields}
                textStyle={textFieldStyle}
                placeholder={t('common.email')}
                fullWidth
              />
              <TextInputFormik
                name="password"
                type="password"
                className={classes.fields}
                textStyle={textFieldStyle}
                placeholder={t('common.password')}
                fullWidth
              />
              <RoundButton
                type="submit"
                className={classes.fields}
                fullWidth
                color="primary"
                disabled={isLoading}
              >
                {t('containers.SignUpPage.signUp')}
              </RoundButton>
              {error && <Typography color="error">{error.message}</Typography>}
            </Form>
          )}
        </Formik>
        <RoundButton
          className={classes.fb}
          onClick={() => dispatch(loginFB(history))}
          disabled={typeof FB === 'undefined' || !window.fbAsyncInit.hasRun}
        >
          {t('containers.SignUpPage.signUpFB')}
        </RoundButton>
        <Box className={classes.linkContainer}>
          <Link href="signIn" className={classes.link} variant={'body2'}>
            {t('containers.SignUpPage.signIn')}
          </Link>
        </Box>
      </Container>
    </>
  );
};

const useStyles = makeStyles({
  fields: {
    marginTop: '6px',
    marginBottom: '10px',
    textTransform: 'capitalize',
  },
  linkContainer: {
    marginTop: 10,
    width: '100%',
    textAlign: 'center',
  },
  link: {
    color: 'gray',
    fontSize: 'small',
  },
  fb: {
    marginTop: 0,
    width: '100%',
    padding: 5,
    borderRadius: 30,
    color: 'red',
    fontSize: 14,
    border: '1px solid #151515',
    background: 'white',
  },
});

export default SignInPage;
