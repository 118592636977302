import produce from 'immer';
import { ACTIONS } from '../actions/config';

const initialState = {
  config: null,
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case ACTIONS.REQUEST_CONFIG:
      draft.isLoadingConfig = true;
      draft.errorConfig = null;
      break;
    case ACTIONS.SUCCESS_CONFIG:
      draft.isLoadingConfig = false;
      draft.config = payload.config;
      draft.errorConfig = null;
      break;
    case ACTIONS.ERROR_CONFIG:
      draft.isLoadingConfig = false;
      draft.config = null;
      draft.errorConfig = payload.error;
      break;
    default:
      break;
  }
}, initialState);

export default reducer;

export const selectors = {
  config: (state) => state.config.config,
};
