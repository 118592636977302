// @flow

import { Box, CardMedia, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { callWaiter } from '../actions/menu';
import profile from '../icons/profile.svg';
import { selectors as menuSelectors } from '../reducers/menu';
import { selectors as userSelectors } from '../reducers/user';
import Icon from './Icon';
import PopupDialog from './PopupDialog';
import RoundButton from './RoundButton';
import WaiterCallPopup from './WaiterCallPopup';

type Props = {
  restaurant: Object,
  order?: boolean,
  oldOrder?: boolean,
};

const useStyles = makeStyles({
  header: {
    position: 'relative',
    height: 250,
    flexShrink: 0,
    flexGrow: 0,
  },
  coverPhoto: {
    height: 250,
  },
  settingsIcon: {
    position: 'absolute',
    top: 20,
    left: 20,
  },
  restaurantInfo: {
    position: 'absolute',
    bottom: 20,
    left: 20,
  },
  name: {
    fontWeight: 'bold',
    fontSize: 30,
  },
  subtitleBox: {
    display: 'flex',
    flexDirection: 'row',
  },
  callWaiter: {
    position: 'absolute',
    right: 20,
    top: 20,
  },
  white: {
    color: 'white',
  },
  modalTitle: {
    padding: 20,
    fontWeight: 'bold',
  },
  modalButtons: {
    position: 'absolute',
    bottom: 20,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalCancel: {
    marginRight: 20,
  },
  modalConfirm: {
    color: 'white',
  },
  close: {
    position: 'absolute',
    top: 10,
    right: 20,
  },
  closeButton: {
    color: 'white',
  },
});

// TODO : When restaurant photo is white, there will be UX issues as icons are white. There are various ways to fix this. Simplest is adding an overlay
const Header = ({ restaurant, order, oldOrder }: Props): Node => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const tableNumber = useSelector(menuSelectors.tableNumber);
  const user = useSelector(userSelectors.user);
  const { t } = useTranslation();

  return (
    <Box className={classes.header}>
      {restaurant.get('coverPhoto') && (
        <CardMedia
          className={classes.coverPhoto}
          image={restaurant.get('coverPhoto').url()}
          title="Menu Cover Photo"
        />
      )}
      <Box
        className={classes.settingsIcon}
        onClick={() => history.push('/settings')}
      >
        <Icon svg={profile} />
      </Box>
      <Box className={classes.restaurantInfo}>
        <Typography className={classNames(classes.name, classes.white)}>
          {restaurant.get('name')}
        </Typography>
        <Box className={classes.subtitleBox}>
          {!order && (
            <>
              <Typography className={classes.white}>
                {restaurant.get('rating')}
              </Typography>
              {restaurant.get('reviewsCount') && (
                <Typography className={classes.white}>
                  &nbsp;
                  {`(${restaurant.get('reviewsCount')})`}
                </Typography>
              )}
            </>
          )}
          {order && (
            <>
              <Typography
                className={classes.white}
              >{`#${order.id}`}</Typography>
            </>
          )}
        </Box>
      </Box>
      {!oldOrder && (
        <Box className={classes.callWaiter} onClick={() => setIsOpen(true)}>
          <Typography className={classes.white}>
            {t('components.Header.callWaiter')}
          </Typography>
        </Box>
      )}
      {!oldOrder && (
        <PopupDialog isOpen={modalIsOpen}>
          <>
            <Typography className={classes.modalTitle}>
              {t('components.Header.callWaiterQuestion')}
            </Typography>
            <Box className={classes.modalButtons}>
              <RoundButton
                className={classes.modalCancel}
                onClick={() => setIsOpen(false)}
              >
                {t('common.cancel')}
              </RoundButton>
              <RoundButton
                className={classes.modalConfirm}
                color="primary"
                onClick={() => {
                  dispatch(
                    callWaiter(user, tableNumber, restaurant, 'waiterCall'),
                  );
                  setIsOpen(false);
                }}
              >
                {t('common.confirm')}
              </RoundButton>
            </Box>
          </>
        </PopupDialog>
      )}
      {!oldOrder && <WaiterCallPopup />}
      {oldOrder && (
        <Box className={classes.close} onClick={history.goBack}>
          <Typography variant="h4" className={classes.closeButton}>
            x
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Header;
