// @flow
import { Box, TextField, Typography } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { saveReview } from '../actions/order';
import RoundButton from '../components/RoundButton';
import { selectors as menuSelectors } from '../reducers/menu';

import { selectors as userSelectors } from '../reducers/user';

type Props = {
  order: Object,
  isOpen: boolean,
  setIsOpen: Function,
};

const useStyles = makeStyles({
  modalContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    boxSizing: 'border-box',
  },
  modalButtons: {
    position: 'absolute',
    bottom: 20,
    display: 'flex',
    width: 'calc(100% - 40px)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalCancel: {
    marginRight: 20,
  },
  modalConfirm: {
    color: 'white',
    margin: 20,
  },
  title: { marginBottom: 40 },
  firstLine: {
    display: 'flex',
  },
  reviewPoint: {
    flexGrow: 1,
  },
  emoji: {
    flexGrow: 0,
    fontSize: '20px',
  },
  slider: {
    width: '99%',
  },
  instructions: {
    paddingTop: 20,
    width: '100%',
    flexGrow: 1,
  },
  instructionsField: {
    width: '100%',
  },
});

const modalStyles = {
  content: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: 0,
    border: 0,
  },
};

const Review = ({ order, isOpen, setIsOpen }: Props): Node => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const restaurant = useSelector(menuSelectors.restaurant);
  const tableOrder = useSelector(menuSelectors.tableOrder);
  const user = useSelector(userSelectors.user);

  const reviewsPoints = restaurant.get('reviewsPoints');
  const defaultValues = [];
  reviewsPoints.map((reviewsPoint) =>
    defaultValues.push({ name: reviewsPoint, value: 5 }),
  );
  const [reviews, setReviews] = React.useState(defaultValues);
  const [comment, setComment] = React.useState('');

  const waiterId = tableOrder.get('waiter').id;
  const emojis = ['🤕', '😕', '😔', '😌', '😋', '😍'];
  const handleChange = (event, reviewsPoint, newValue) => {
    const newValues = [...reviews].filter(
      (valueObject) => valueObject.name !== reviewsPoint,
    );
    newValues.push({ name: reviewsPoint, value: newValue });
    setReviews(newValues);
  };

  return (
    <Modal isOpen={isOpen} style={modalStyles}>
      <Box className={classes.modalContainer}>
        <Box className={classes.title}>
          <Typography variant="h5">{t('containers.Review.title')}</Typography>
        </Box>
        <Box>
          {reviewsPoints.map((reviewsPoint) => {
            const value = reviews.find(
              (valueObject) => valueObject.name === reviewsPoint,
            ).value;
            return (
              <Box key={reviewsPoint}>
                <Box className={classes.firstLine}>
                  <Typography className={classes.reviewPoint}>
                    {reviewsPoint}
                  </Typography>
                  <Typography className={classes.emoji}>
                    {emojis[value]}
                  </Typography>
                </Box>
                <Slider
                  value={value}
                  max={5}
                  onChange={(event, newValue) =>
                    handleChange(event, reviewsPoint, newValue)
                  }
                  classes={{
                    root: classes.slider,
                  }}
                />
              </Box>
            );
          })}
        </Box>
        <Box className={classes.instructions}>
          <TextField
            id="outlined-basic"
            label={t('common.comment')}
            variant="outlined"
            className={classes.instructionsField}
            value={comment}
            onChange={(event) => {
              setComment(event.target.value);
            }}
          />
        </Box>
        <Box className={classes.modalButtons}>
          <RoundButton
            className={classes.modalCancel}
            onClick={() => setIsOpen(false)}
          >
            {t('common.cancel')}
          </RoundButton>
          <RoundButton
            className={classes.modalConfirmSmall}
            color="primary"
            onClick={() => {
              setIsOpen(false);
              dispatch(
                saveReview(
                  user.id,
                  order.id,
                  restaurant.id,
                  comment,
                  reviews,
                  waiterId,
                ),
              );
            }}
          >
            {t('common.confirm')}
          </RoundButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default Review;
