import produce from 'immer';
import { ACTIONS } from '../actions/user';

const initialState = {
  isLogged: false,
  user: null,
  error: null,
  isLoading: false,
  stripeUser: null,
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_CURRENT_USER:
      draft.user = payload.user;
      draft.isLogged = !!payload.user;
      break;
    case ACTIONS.SIGN_IN:
    case ACTIONS.SIGN_UP:
      draft.isLoading = true;
      draft.error = null;
      break;
    case ACTIONS.SIGN_IN_SUCCESS:
    case ACTIONS.SIGN_UP_SUCCESS:
      draft.isLoading = false;
      draft.isLogged = true;
      draft.user = payload.user;
      draft.error = null;
      break;
    case ACTIONS.SIGN_IN_ERROR:
    case ACTIONS.SIGN_UP_ERROR:
      draft.isLoading = false;
      draft.isLogged = false;
      draft.user = null;
      draft.error = payload.error;
      break;
    case ACTIONS.LOGOUT:
      draft.user = null;
      draft.isLogged = false;
      break;
    case ACTIONS.FETCH_STRIPE_USER_SUCCESS:
      draft.stripeUser = payload.stripeUser;
      break;
    default:
      break;
  }
}, initialState);

export default reducer;

export const selectors = {
  user: (state) => state.user.user,
  stripeUser: (state) => state.user.stripeUser,
  isLogged: (state) => state.user.isLogged,
  isLoading: (state) => state.user.isLoading,
  error: (state) => state.user.error,
};
