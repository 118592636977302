import { call, fork, put, take, takeLatest } from 'redux-saga/effects';
import Parse from 'parse';
import * as Sentry from '@sentry/browser';
import {
  ACTIONS,
  fetchRestaurantError,
  fetchRestaurantSuccess,
  fetchTableOrderError,
  fetchTableOrderSuccess,
  markRestaurantSocketLoading,
  markRestaurantSocketOpened,
  setWaiterCallSent,
  tableOrderIsClosed,
  updateCurrentOrder,
} from '../actions/menu';
import computeTableOrderQuery from './computeTableOrderQuery';
import createLiveQueryChannel from './createChannel';
import watchCloseChannelMessage from './watchCloseChannelMessage';

function* requestRestaurantSaga(action) {
  const { restaurantId } = action.payload;
  const Restaurant = Parse.Object.extend('Restaurant');
  const query = new Parse.Query(Restaurant);
  try {
    const restaurant = yield call(() => query.get(restaurantId));
    yield put(fetchRestaurantSuccess(restaurant));
  } catch (error) {
    yield put(fetchRestaurantError(error));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.info('requestRestaurantSaga error', error);
  }
}

function* callWaiterSaga(action) {
  const { user, tableNumber, restaurant, type } = action.payload;
  const WaiterCall = Parse.Object.extend('WaiterCall');
  const waiterCall = new WaiterCall({
    user,
    tableNumber,
    restaurant,
    type,
    seen: false,
  });
  try {
    yield waiterCall.save();
    yield put(setWaiterCallSent(true));
  } catch (error) {
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.info('callWaiterSaga error', error);
  }
}

function* initRestaurantSocket(action) {
  const { restaurantId } = action.payload;
  const Restaurant = Parse.Object.extend('Restaurant');
  const query = new Parse.Query(Restaurant);
  query.equalTo('objectId', restaurantId);
  yield put(markRestaurantSocketLoading());
  const subscription = yield query.subscribe();
  const channel = yield createLiveQueryChannel(
    subscription,
    null,
    fetchRestaurantSuccess,
    null,
    null,
    'RestaurantSocket',
  );
  yield put(markRestaurantSocketOpened());
  yield fork(watchCloseChannelMessage, channel, 'RestaurantSocket');
  try {
    while (true) {
      const action = yield take(channel);
      yield put(action);
    }
  } catch (e) {
    console.error('Error in RestaurantSocket', e);
    Sentry.captureException(e);
  }
}

function* firstRequestTableOrderSaga(action) {
  const { restaurant, tableNumber, user } = action.payload;
  const query = computeTableOrderQuery({ restaurant, tableNumber });
  try {
    const tableOrders = yield query.find();
    const tableOrder = tableOrders.filter(
      (tableOrder) => tableOrder.get('state') !== 'closed', // TODO : this assumes there can only be one table whose status is not "closed", and it should be true but currently isn't enforced
    )[0];
    yield put(fetchTableOrderSuccess(tableOrder));
    const currentUserOrder = tableOrder
      .get('orders')
      .find((order) => order.get('userId') === user.id);
    if (currentUserOrder) {
      yield put(updateCurrentOrder(currentUserOrder));
    } else {
      const Order = Parse.Object.extend('Order');
      const newOrder = new Order({
        order: [],
        restaurantId: restaurant.id,
        userId: user.id,
        user: user,
        state: 'pending',
        restaurant: restaurant,
        tableOrder: tableOrder,
        tip: 0.18,
        paid: false,
        reviewed: false,
        paymentMode: 'card',
        refunds: [],
      });
      yield newOrder.save();
      const orders = tableOrder.get('orders');
      const usersId = tableOrder.get('usersId')
        ? tableOrder.get('usersId')
        : [];
      orders.push(newOrder);
      tableOrder.set('orders', orders);
      if (usersId.indexOf(user.id) === -1) {
        usersId.push(user.id);
        tableOrder.set('usersId', usersId);
        // Store how many times the user has been to this restaurant
        const visitsCount = user.get('visitsCount') || [];
        const currentRestaurantVisitsCount = visitsCount.find(
          (obj) => obj.restaurantId === restaurant.id,
        ) || { restaurantId: restaurant.id, visits: 0 };
        currentRestaurantVisitsCount.visits++;
        const filteredVisitsCount = visitsCount.filter(
          (obj) => obj.restaurantId !== restaurant.id,
        );
        filteredVisitsCount.push(currentRestaurantVisitsCount);
        user.set('visitsCount', filteredVisitsCount);
        yield user.save();
      }
      yield tableOrder.save();
      yield put(updateCurrentOrder(newOrder));
      yield put(fetchTableOrderSuccess(tableOrder));
    }
  } catch (error) {
    yield put(fetchTableOrderError(error));
  }
}

function* requestTableOrderSaga(action) {
  const { restaurant, tableNumber } = action.payload;
  const query = computeTableOrderQuery({ restaurant, tableNumber });
  try {
    const tableOrders = yield query.find();
    const tableOrder = tableOrders.filter(
      (tableOrder) => tableOrder.get('state') !== 'closed',
    )[0]; // TODO : this assumes there can only be one table whose status is not "closed", and it should be true but currently isn't enforced
    // Once the table order is closed, we no longer receive liveQuery info, so we need this to inform the client
    if (!tableOrder) {
      yield put(tableOrderIsClosed());
    } else {
      yield put(fetchTableOrderSuccess(tableOrder));
    }
  } catch (error) {
    yield put(fetchTableOrderError(error));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.info('requestTableOrderSaga error', error);
  }
}

export default function* () {
  yield takeLatest(ACTIONS.REQUEST, requestRestaurantSaga);
  yield takeLatest(ACTIONS.OPEN_RESTAURANT_SOCKET, initRestaurantSocket);
  yield takeLatest(ACTIONS.TABLE_ORDER_REQUEST, requestTableOrderSaga);
  yield takeLatest(
    ACTIONS.FIRST_TABLE_ORDER_REQUEST,
    firstRequestTableOrderSaga,
  );
  yield takeLatest(ACTIONS.CALL_WAITER, callWaiterSaga);
}
