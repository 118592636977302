import Parse from 'parse';
import { subDays } from 'date-fns';

const TableOrder = Parse.Object.extend('TableOrder');
const fromDate = subDays(Date.now(), 1);

const computeTableOrderQuery = ({ restaurant, tableNumber }) => {
  const query = new Parse.Query(TableOrder);
  query.equalTo('restaurant', restaurant);
  query.equalTo('tableNumber', tableNumber);
  query.greaterThan('createdAt', fromDate);
  query.include('orders');
  query.include('orders.user');
  query.include('orders.discountPointer');
  query.include('orders.discountPointer.discount');
  query.include('orders.refunds');
  query.include('orders.refunds.refund');
  return query;
};

export default computeTableOrderQuery;
