import produce from 'immer';
import { ACTIONS } from '../actions/menu';
import { ACTIONS as USER_ACTIONS } from '../actions/user';

const initialState = {
  // restaurantId: 'd4IRxtNwke',
  // tableNumber: '2',
  restaurantId: null,
  tableNumber: null,
  tableOrder: null,
  restaurant: null,
  error: null,
  tableOrderError: null,
  isLoading: false,
  tableOrderIsLoading: false,
  config: null,
  waiterCallSent: false,
  stripeLoaded: false,
  stripeLoading: false,
  stripeLoadError: null,
  tableOrderClosed: false,
  restaurantSocketLoading: false,
  restaurantSocketOpened: false,
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case ACTIONS.RESTAURANT_ID_AND_TABLE_SCANNED:
      draft.restaurantId = payload.restaurantId;
      draft.tableNumber = payload.tableNumber;
      draft.tableOrderSocketOpened = false;
      break;
    case ACTIONS.REQUEST:
      draft.isLoading = true;
      draft.error = null;
      break;
    case ACTIONS.SUCCESS:
      draft.isLoading = false;
      draft.restaurant = payload.restaurant;
      draft.error = null;
      break;
    case ACTIONS.ERROR:
      draft.isLoading = false;
      draft.restaurant = null;
      draft.error = payload.error;
      break;
    case ACTIONS.REQUEST_CONFIG:
      draft.isLoadingConfig = true;
      draft.errorConfig = null;
      break;
    case ACTIONS.SUCCESS_CONFIG:
      draft.isLoadingConfig = false;
      draft.config = payload.config;
      draft.errorConfig = null;
      break;
    case ACTIONS.ERROR_CONFIG:
      draft.isLoadingConfig = false;
      draft.config = null;
      draft.errorConfig = payload.error;
      break;
    case ACTIONS.TABLE_ORDER_REQUEST:
      draft.tableOrderIsLoading = true;
      draft.tableOrderError = null;
      break;
    case ACTIONS.TABLE_ORDER_SUCCESS:
      draft.tableOrderIsLoading = false;
      draft.tableOrder = payload.tableOrder;
      draft.tableOrderError = null;
      break;
    case ACTIONS.TABLE_ORDER_ERROR:
      draft.tableOrderIsLoading = false;
      draft.restaurant = null;
      draft.tableOrderError = payload.error;
      break;
    case USER_ACTIONS.LOGOUT:
    case ACTIONS.SWITCH_RESTAURANT:
      draft.restaurantId = null;
      draft.tableNumber = null;
      draft.tableOrder = null;
      draft.tableOrderError = null;
      draft.tableOrderClosed = false;
      break;
    case ACTIONS.WAITER_CALL_SENT:
      draft.waiterCallSent = payload;
      break;
    case ACTIONS.TABLE_ORDER_CLOSED:
      draft.tableOrderClosed = true;
      break;
    case ACTIONS.RESET_TABLE_ORDER_CLOSED:
      draft.tableOrderClosed = false;
      break;

    default:
      break;
  }
}, initialState);

export default reducer;

export const selectors = {
  restaurantId: (state) => state.restaurant.restaurantId,
  tableNumber: (state) => state.restaurant.tableNumber,
  restaurant: (state) => state.restaurant.restaurant,
  config: (state) => state.restaurant.config,
  tableOrder: (state) => state.restaurant.tableOrder,
  tableOrderError: (state) => state.restaurant.tableOrderError,
  isLoading: (state) => state.restaurant.isLoading,
  error: (state) => state.restaurant.error,
  waiterCallSent: (state) => state.restaurant.waiterCallSent,
  tableOrderClosed: (state) => state.restaurant.tableOrderClosed,
  stripeLoadError: (state) => state.restaurant.stripeLoadError,
};
