import { all, fork } from 'redux-saga/effects';
import userSaga from '../sagas/user';
import restaurantSaga from '../sagas/menu';
import orderSaga from '../sagas/order';
import configSaga from '../sagas/config';
import versionSaga from '../sagas/version';

export default function* () {
  yield all([
    fork(userSaga),
    fork(restaurantSaga),
    fork(orderSaga),
    fork(configSaga),
    fork(versionSaga),
  ]);
}
