import { takeLatest, put } from 'redux-saga/effects';
import Parse from 'parse';
import * as Sentry from '@sentry/browser';
import {
  ACTIONS,
  signInSuccess,
  signInError,
  signUpError,
  signUpSuccess,
  fetchStripeUserSuccess,
  fetchStripeUser,
} from '../actions/user';
import toDataURL from '../utils/toDataUrl';

function* updateACL(user) {
  const roleQuery = new Parse.Query(Parse.Role);
  roleQuery.equalTo('name', 'User');

  try {
    const role = yield roleQuery.first();

    const userACL = new Parse.ACL(Parse.User.current());
    userACL.setPublicReadAccess(false);
    userACL.setRoleReadAccess(role, true);
    user.setACL(userACL);
    yield user.save();

    role.getUsers().add(user);
    yield role.save();
  } catch (error) {
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.info('updateACL error', error);
  }
}

function* signUpSaga(action) {
  const { name, email, password } = action.payload;
  const user = new Parse.User();

  user.set('username', email);
  user.set('name', name);
  user.set('email', email);
  user.set('password', password);

  try {
    yield user.signUp();
    yield updateACL(user);
    yield put(signUpSuccess(user));
  } catch (error) {
    yield put(signUpError(error));
  }
}

function* signInSaga(action) {
  const { email, password } = action.payload;

  try {
    const user = yield Parse.User.logIn(email, password);
    yield put(signInSuccess(user));
  } catch (error) {
    yield put(signInError(error));
  }
}

function* loginFB(action) {
  const { history } = action.payload;

  try {
    const user = yield Parse.FacebookUtils.logIn('public_profile,email');
    yield updateACL(user);
    // eslint-disable-next-line no-undef
    FB.api('/me?fields=name,email,picture.width(300),id', function (response) {
      if (
        !user.get('name') ||
        !user.get('email') ||
        !user.get('profilePicture') ||
        !user.get('facebookId')
      ) {
        if (!user.get('name')) user.set('name', response.name);
        if (!user.get('email')) user.set('email', response.email);
        if (!user.get('facebookId')) user.set('facebookId', response.id);
        if (!user.get('profilePicture') && response.picture) {
          toDataURL(response.picture.data.url, (dataUrl) => {
            const picture = new Parse.File('profilePicture', {
              base64: dataUrl,
            });
            picture.save().then(
              () => {
                user.set('profilePicture', picture);
                user.save().then(() => {
                  put(signInSuccess(user));
                  history.go(0); // Refresh page to show menu
                });
              },
              (error) => {
                Sentry.captureException(error);
                // eslint-disable-next-line no-console
                console.error('Error saving profile picture', error);
              },
            );
          });
        } else {
          user.save();
          put(signInSuccess(user));
          history.go(0); // Refresh page to show menu
        }
      } else {
        put(signInSuccess(user));
        history.go(0); // Refresh page to show menu
      }
    });
  } catch (error) {
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.error('FB login error', error);
    yield put(signInError(error));
  }
}

function* logoutSaga(action) {
  const { history } = action.payload;
  yield Parse.User.logOut();
  history.push('/');
}

function* addPaymentMethodSaga(action) {
  const { token, userId, user, history } = action.payload;

  try {
    const response = yield Parse.Cloud.run('addPaymentMethod', {
      token,
      userId,
    });
    if (!user.get('stripeId')) {
      user.set('stripeId', response.customer);
      yield user.save();
      yield put(fetchStripeUser(user));
      history.goBack();
    } else {
      yield put(fetchStripeUser(user));
      history.goBack();
    }
  } catch (error) {
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.info('addPaymentMethodSaga error', error);
  }
}

function* fetchStripeUserSaga(action) {
  const { user } = action.payload;

  try {
    const stripeUser = yield Parse.Cloud.run('retrieveStripeUser', {
      userId: user.id,
    });
    yield put(fetchStripeUserSuccess(stripeUser));
  } catch (error) {
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.info('fetchStripeUserSaga error', error);
  }
}

function* updateUserNameSaga(action) {
  const { name, user } = action.payload;
  user.set('name', name);
  try {
    yield user.save();
  } catch (error) {
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.info('updateUserNameSaga error', error);
  }
}

export default function* () {
  yield takeLatest(ACTIONS.SIGN_UP, signUpSaga);
  yield takeLatest(ACTIONS.SIGN_IN, signInSaga);
  yield takeLatest(ACTIONS.LOGIN_FB, loginFB);
  yield takeLatest(ACTIONS.LOGOUT, logoutSaga);
  yield takeLatest(ACTIONS.ADD_PAYMENT_METHOD, addPaymentMethodSaga);
  yield takeLatest(ACTIONS.FETCH_STRIPE_USER, fetchStripeUserSaga);
  yield takeLatest(ACTIONS.UPDATE_USER_NAME, updateUserNameSaga);
}
