import produce from 'immer';
import Parse from 'parse';
import { ACTIONS } from '../actions/version';

const Version = Parse.Object.extend('Version');

const defaultVersionObject = new Version({
  platform: 'client',
  productionVersion: process.env.REACT_APP_VERSION,
});

const initialState = {
  version: defaultVersionObject,
  isOnline: true,
};

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case ACTIONS.REQUEST_VERSION:
      draft.isLoadingVersion = true;
      draft.errorVersion = null;
      break;
    case ACTIONS.SUCCESS_VERSION:
      draft.isLoadingVersion = false;
      draft.version = payload.version;
      draft.errorVersion = null;
      break;
    case ACTIONS.ERROR_VERSION:
      draft.isLoadingVersion = false;
      draft.version = null;
      draft.errorVersion = payload.error;
      break;
    case ACTIONS.IS_ONLINE:
      draft.isOnline = payload;
      break;
    default:
      break;
  }
}, initialState);

export default reducer;

export const selectors = {
  version: (state) => state.version.version,
  isOnline: (state) => state.version.isOnline,
  errorVersion: (state) => state.version.errorVersion,
};
