// @flow
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as classNames from 'classnames';
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { updateTip } from '../actions/order';
import RoundButton from '../components/RoundButton';
import formatMoney from '../utils/formatMoney';

const useStyles = makeStyles({
  modalContainer: {
    width: '100%',
    height: '100%',
    paddingLeft: '5%',
  },
  tipBox: {
    backgroundColor: '#f8f8f8',
    display: 'inline-block',
    width: '42.5%',
    padding: 20,
    marginTop: '5%',
    marginRight: '5%',
    borderRadius: 10,
    boxSizing: 'border-box',
  },
  redBorder: {
    border: '3px solid red',
  },
  tipPercentage: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  tipPercentageNumber: {
    fontSize: 30,
    fontWeight: 'bold',
  },
  tipPercentageUnit: {
    fontSize: 18,
    lineHeight: '50px',
  },
  tipAmount: {
    display: 'flex',
    justifyContent: 'center',
  },
  tipAmountText: {
    fontSize: 14,
  },
  modalButtonContainer: {
    width: '100%',
    padding: 20,
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    boxSizing: 'border-box',
  },
});

// TODO : This is used in a bunch of places, can be factorized
const modalStyles = {
  content: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: 0,
    border: 0,
  },
};

type Props = {
  price: number,
  orderTip: number,
  modalIsOpen: boolean,
  tips: Array,
  closeModal: Function,
  order: Object,
  restaurant: Object,
};

const Tips = (props: Props): Node => {
  const {
    price,
    orderTip,
    modalIsOpen,
    tips,
    closeModal,
    order,
    restaurant,
  } = props;
  const classes = useStyles();
  const [selectedTip, setSelectedTip] = React.useState(null);
  const activeTip = selectedTip !== null ? selectedTip : orderTip;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const locale = restaurant.get('country');
  const currency = restaurant.get('currency');

  return (
    <Modal isOpen={modalIsOpen} style={modalStyles}>
      <Box className={classes.modalContainer}>
        {tips.map((tip) => (
          <Box
            key={tip}
            className={classNames(
              classes.tipBox,
              activeTip === tip ? classes.redBorder : null,
            )}
            onClick={() => setSelectedTip(tip)}
          >
            <Box className={classes.tipPercentage}>
              <Typography className={classes.tipPercentageNumber}>
                {tip}
              </Typography>
              <Typography className={classes.tipPercentageUnit}>%</Typography>
            </Box>
            <Box className={classes.tipAmount}>
              <Typography className={classes.tipAmountText}>
                {formatMoney((price * tip) / 100, locale, currency)}
              </Typography>
            </Box>
          </Box>
        ))}
        <Box className={classes.modalButtonContainer}>
          <RoundButton
            className={classes.button}
            fullWidth
            color="primary"
            onClick={() => {
              dispatch(updateTip(activeTip, order));
              setSelectedTip(null);
              closeModal();
            }}
          >
            {t('containers.Tips.updateTip')}
          </RoundButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default Tips;
