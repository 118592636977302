// @flow
export const ACTIONS = {
  SET_CURRENT_USER: 'user/setCurrentUser',
  LOGIN_FB: 'user/loginFB',
  SIGN_UP: 'user/signUp',
  SIGN_UP_SUCCESS: 'user/signUp/success',
  SIGN_UP_ERROR: 'user/signUp/error',
  SIGN_IN: 'user/signIn',
  SIGN_IN_SUCCESS: 'user/signIn/success',
  SIGN_IN_ERROR: 'user/signIn/error',
  LOGOUT: 'user/logout',
  ADD_PAYMENT_METHOD: 'user/addPaymentMethod',
  FETCH_STRIPE_USER: 'user/fetchStripeUser',
  FETCH_STRIPE_USER_SUCCESS: 'user/fetchStripeUserSuccess',
  UPDATE_USER_NAME: 'user/updateUserName',
};

export const setCurrentUser = (user: Object): Object => ({
  type: ACTIONS.SET_CURRENT_USER,
  payload: { user },
});

export const signUp = (
  name: string,
  email: string,
  password: string,
): Object => ({
  type: ACTIONS.SIGN_UP,
  payload: { name, email, password },
});

export const signUpSuccess = (user: Object): Object => ({
  type: ACTIONS.SIGN_UP_SUCCESS,
  payload: { user },
});

export const signUpError = (error: Error): Object => ({
  type: ACTIONS.SIGN_UP_ERROR,
  payload: { error },
});

export const signIn = (email: string, password: string): Object => ({
  type: ACTIONS.SIGN_IN,
  payload: { email, password },
});

export const signInSuccess = (user: Object): Object => ({
  type: ACTIONS.SIGN_IN_SUCCESS,
  payload: { user },
});

export const signInError = (error: Error): Object => ({
  type: ACTIONS.SIGN_IN_ERROR,
  payload: { error },
});

export const loginFB = (history: Object): Object => ({
  type: ACTIONS.LOGIN_FB,
  payload: { history },
});

export const logout = (history: Object): Object => ({
  type: ACTIONS.LOGOUT,
  payload: { history },
});

// TODO: handle loading and error
export const addPaymentMethod = (
  token: string,
  userId: string,
  user: Object,
  history: Object,
): Object => ({
  type: ACTIONS.ADD_PAYMENT_METHOD,
  payload: { token, userId, user, history },
});

export const fetchStripeUser = (user: Object): Object => ({
  type: ACTIONS.FETCH_STRIPE_USER,
  payload: { user },
});

export const fetchStripeUserSuccess = (stripeUser: Object): Object => ({
  type: ACTIONS.FETCH_STRIPE_USER_SUCCESS,
  payload: { stripeUser },
});

export const updateUserName = (name: String, user: Object): Object => ({
  type: ACTIONS.UPDATE_USER_NAME,
  payload: { name, user },
});
