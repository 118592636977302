// @flow
import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import i18next from 'i18next';
import React, { type Node, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { fetchConfig } from '../actions/config';
import { switchRestaurant } from '../actions/menu';
import { fetchStripeUser } from '../actions/user';
import {
  fetchVersion,
  openVersionSocket,
  setIsOnline,
} from '../actions/version';
import { selectors as menuSelectors } from '../reducers/menu';
import { selectors as socketSelectors } from '../reducers/socket';
import { selectors as userSelectors } from '../reducers/user';
import { selectors as versionSelectors } from '../reducers/version';
import { selectors as orderSelectors } from '../reducers/order';
import AddCard from './AddCard';
import Menu from './Menu';
import OldOrder from './OldOrder';
import Order from './Order';
import Orders from './Orders';
import Settings from './Settings';
import Terms from './Terms';
import Wallet from './Wallet';
import Paypal from './Paypal';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    background: 'white',
    color: theme.palette.primary.main,
  },
  title: {
    marginBottom: 20,
  },
}));

const LoggedSwitch = (): Node => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const { addToast } = useToasts();

  const user = useSelector(userSelectors.user);
  const versionSocketLoading = useSelector(
    socketSelectors.versionSocketLoading,
  );
  const versionSocketOpened = useSelector(socketSelectors.versionSocketOpened);
  const tableOrderClosed = useSelector(menuSelectors.tableOrderClosed);
  const menuError = useSelector(menuSelectors.error);
  const stripeLoadError = useSelector(menuSelectors.stripeLoadError);
  const restaurant = useSelector(menuSelectors.restaurant);
  const isOnline = useSelector(versionSelectors.isOnline);
  const version = useSelector(versionSelectors.version);
  const orderLoadingError = useSelector(orderSelectors.errorLoadingOrders);
  const paymentError = useSelector(orderSelectors.paymentError);
  const orderError = useSelector(orderSelectors.error);

  // Force reload when back online (to ensure data is up to date even when socket connection has been lost)
  useEffect(() => {
    const reloadWhenBackOnline = () => {
      dispatch(setIsOnline(true));
      document.location.reload();
    };
    window.addEventListener('online', reloadWhenBackOnline);
    return () => {
      window.removeEventListener('online', reloadWhenBackOnline);
    };
  }, []);

  // Set marker when offline
  useEffect(() => {
    const markOffline = () => {
      dispatch(setIsOnline(false));
    };
    window.addEventListener('offline', markOffline);
    return () => {
      window.removeEventListener('offline', markOffline);
    };
  }, []);

  // Bootstrap the app
  useEffect(() => {
    Modal.setAppElement('body');
    dispatch(fetchConfig());
    dispatch(fetchVersion());
    if (restaurant?.get('paymentSystem') === 'stripe') {
      dispatch(fetchStripeUser(user));
    }
    if (!versionSocketLoading && !versionSocketOpened) {
      dispatch(openVersionSocket());
    }
  }, [versionSocketLoading, versionSocketOpened, user, dispatch]);

  // If the table has been closed by the restaurant, we clean the state to bring the user back to the QR code screen
  useEffect(() => {
    if (tableOrderClosed) {
      dispatch(switchRestaurant());
      history.push('/');
    }
  });

  useEffect(() => {
    if (
      menuError ||
      stripeLoadError ||
      orderLoadingError ||
      paymentError ||
      orderError
    ) {
      addToast(i18next.t('toasts.error'), { appearance: 'error' });
    }
  }, [menuError, stripeLoadError, orderLoadingError, paymentError, orderError]);

  // If the user's connection goes down, we notify and lock the UI
  if (!isOnline) {
    return (
      <Container className={classes.container}>
        <Typography variant="h5" className={classes.title}>
          {t('containers.Offline.title')}
        </Typography>
        <Typography>{t('containers.Offline.content')}</Typography>
      </Container>
    );
  }

  // If the code is stale, we notify and lock the UI
  const versionNumber = version ? version.get('productionVersion') : null;
  if (process.env.REACT_APP_VERSION !== versionNumber) {
    return (
      <Container className={classes.container}>
        <Typography variant="h5" className={classes.title}>
          {t('containers.Refresh.title')}
        </Typography>
        <Typography>{t('containers.Refresh.content')}</Typography>
        <br />
        <Typography>{process.env.REACT_APP_VERSION}</Typography>
      </Container>
    );
  }

  return (
    <Switch>
      <Redirect from="/signup" to="/" />
      <Route exact path="/" component={Menu} />
      <Route path="/menu" component={Menu} />
      <Route path="/order" component={Order} />
      <Route exact path="/orders" component={Orders} />
      <Route path="/orders/:orderId" component={OldOrder} />
      <Route path="/add-card" component={AddCard} />
      <Route path="/settings" component={Settings} />
      <Route path="/wallet" component={Wallet} />
      <Route path="/terms" component={Terms} />
      <Route path="/paypal/:orderId" component={Paypal} />
      <Route exact path="/:restaurantIdAndTable" component={Menu} />
      <Redirect from="*" to="/" />
    </Switch>
  );
};

export default LoggedSwitch;
