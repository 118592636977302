// @flow
import React, { type Node, useEffect } from 'react';
import { Formik, Form } from 'formik';
import {
  Box,
  makeStyles,
  Typography,
  Link,
  Container,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { restaurantIdAndTableNumberScanned } from '../actions/menu';
import { loginFB, signIn } from '../actions/user';
import { selectors as userSelectors } from '../reducers/user';
import TextInputFormik from '../components/TextInputFormik';
import RoundButton from '../components/RoundButton';
import QrCode from '../components/QrCode';
import { selectors as menuSelectors } from '../reducers/menu';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  fields: {
    marginTop: '6px',
    marginBottom: '10px',
    textTransform: 'capitalize',
  },
  linkContainer: {
    marginTop: 10,
    width: '100%',
    textAlign: 'center',
  },
  link: {
    color: 'gray',
    fontSize: 'small',
  },
  fb: {
    marginTop: 0,
    width: '100%',
    padding: 5,
    borderRadius: 30,
    color: 'red',
    fontSize: 14,
    border: '1px solid #151515',
    background: 'white',
  },
});

const SignInPage = (): Node => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoading = useSelector(userSelectors.isLoading);
  const error = useSelector(userSelectors.error);
  const restaurantId = useSelector(menuSelectors.restaurantId);
  const tableNumber = useSelector(menuSelectors.tableNumber);
  const history = useHistory();
  const textFieldStyle = {
    fontSize: 14,
    color: '#151515',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
  };
  const { restaurantIdAndTable } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (restaurantIdAndTable) {
      const [restaurantId, tableNumber] = restaurantIdAndTable.split('-');
      dispatch(restaurantIdAndTableNumberScanned(restaurantId, tableNumber));
    }
  }, [restaurantIdAndTable]);

  return (
    <>
      {!restaurantId || !tableNumber ? (
        <QrCode />
      ) : (
        <Container style={{ padding: 20, marginTop: 30 }}>
          <Formik
            initialValues={{ email: '', password: '' }}
            onSubmit={(values) => {
              dispatch(signIn(values.email, values.password));
            }}
            validate={(values) => {
              const errors = {};

              if (!values.email) {
                errors.email = 'Required';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address';
              }

              if (!values.password) {
                errors.password = 'Required';
              }
              return errors;
            }}
          >
            {() => (
              <Form>
                <TextInputFormik
                  name="email"
                  className={classes.fields}
                  textStyle={textFieldStyle}
                  placeholder={t('common.email')}
                  fullWidth
                />
                <TextInputFormik
                  name="password"
                  type="password"
                  className={classes.fields}
                  textStyle={textFieldStyle}
                  placeholder={t('common.password')}
                  fullWidth
                />
                <RoundButton
                  type="submit"
                  className={classes.fields}
                  fullWidth
                  color="primary"
                  disabled={isLoading}
                >
                  {t('containers.SignInPage.signIn')}
                </RoundButton>
                {error && (
                  <Typography color="error">{error.message}</Typography>
                )}
              </Form>
            )}
          </Formik>
          <RoundButton
            className={classes.fb}
            onClick={() => dispatch(loginFB(history))}
            disabled={typeof FB === 'undefined' || !window.fbAsyncInit.hasRun}
          >
            {t('containers.SignInPage.signInFB')}
          </RoundButton>
          <Box className={classes.linkContainer}>
            <Link href="signup" className={classes.link} variant={'body2'}>
              {t('containers.SignInPage.signUp')}
            </Link>
          </Box>
        </Container>
      )}
    </>
  );
};

export default SignInPage;
