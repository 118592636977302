// @flow
import React, { type Node } from 'react';
import { makeStyles, Input } from '@material-ui/core';

type Props = {
  name?: string,
  type?: string,
  fullWidth?: boolean,
  onChange?: Function,
  onBlur?: Function,
  value?: string,
  placeholder?: string,
  style?: Object,
  className?: Object,
};

const TextInput = ({
  name,
  type,
  fullWidth,
  onChange,
  onBlur,
  value,
  placeholder,
  style,
  className,
}: Props): Node => {
  const classes = useStyles();

  return (
    <Input
      name={name}
      type={type}
      fullWidth={fullWidth}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      placeholder={placeholder}
      disableUnderline
      classes={{ root: classes.inputRoot }}
      style={style}
      className={className}
    />
  );
};

const useStyles = makeStyles({
  inputRoot: {
    backgroundColor: '#f8f8f8',
    borderRadius: '18px',
    padding: '2px 8px 2px 8px',
  },
});

export default TextInput;
