export default function calculateTotalGroupPrice(tableOrder, restaurant) {
  const totalPriceArray = tableOrder.get('orders').map((order) => {
    if (order.get('paid')) return 0;
    const orderContent = order.get('order');
    const price = orderContent.reduce((accumulator, current) => {
      const orderPrice = current.item.price - (current.item.deal || 0);
      return accumulator + orderPrice * current.number;
    }, 0);
    const tax = price && restaurant ? price * restaurant.get('tax') : 0;
    const tip = price ? price * order.get('tip') : 0;
    const discountObject = order.get('discountPointer');
    const discountValue = discountObject ? discountObject.get('discount') : 0;
    const totalTemp = price + tax + tip - discountValue;
    return totalTemp > 0 ? totalTemp : 0;
  });
  const totalGroupPrice = totalPriceArray.reduce(
    (accumulator, current) => accumulator + current,
    0,
  );
  return Number(totalGroupPrice);
}
