// @flow
import React, { type Node } from 'react';
import Modal from 'react-modal';

type Props = {
  children: string,
  isOpen: boolean,
  height?: number,
  onRequestClose?: Function,
};

const modalStyles = (height) => ({
  content: {
    position: 'relative',
    width: 250,
    height: height ? height : 150,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    textAlign: 'center',
    padding: 0,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
});

// TODO: include title and buttons
const PopupDialog = ({
  children,
  isOpen,
  height,
  onRequestClose,
}: Props): Node => (
  <Modal
    isOpen={isOpen}
    style={modalStyles(height)}
    onRequestClose={onRequestClose}
  >
    {children}
  </Modal>
);

export default PopupDialog;
