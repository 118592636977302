// @flow
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as classNames from 'classnames';
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';
import formatMoney from '../utils/formatMoney';
import Icon from './Icon';
import Remove from '../icons/remove.svg';

const useStyles = makeStyles({
  item: {
    display: 'flex',
    marginBottom: 10,
  },
  numberOfItems: {
    backgroundColor: 'red',
    borderRadius: 30,
    width: 25,
    height: 25,
    textAlign: 'center',
    marginRight: 10,
  },
  textContent: {
    flexGrow: 1,
  },
  comment: {
    fontStyle: 'italic',
    color: '#a8a8a8',
  },
  price: {
    color: 'red',
    fontWeight: 'bold',
  },
  remove: {
    opacity: 0.3,
    marginLeft: 10,
  },
});

type Props = {
  item: Object,
  removable?: boolean,
  removeAction?: Function,
  locale: string,
  currency: string,
};

const Item = ({
  item,
  removable,
  removeAction,
  locale,
  currency,
}: Props): Node => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box
      className={classNames(
        classes.item,
        item.ordered ? classes.ordered : null,
      )}
    >
      <Box className={classes.numberOfItems}>
        <Typography color="secondary">{item.number}</Typography>
      </Box>
      <Box className={classes.textContent}>
        <Typography className={classes.name}>{item.item.name}</Typography>
        <Typography className={classes.comment}>
          {`${
            item.service === 1
              ? t('components.Item.firstService')
              : t('components.Item.secondService')
          } ${item.comment ? ` - ${item.comment}` : ''}`}
        </Typography>
      </Box>
      <Typography className={classes.price}>
        {formatMoney(
          (item.item.price - (item.item.deal || 0)) * item.number,
          locale,
          currency,
        )}
      </Typography>
      {removable && (
        <Icon
          svg={Remove}
          small
          styles={classes.remove}
          onClick={removeAction}
        />
      )}
    </Box>
  );
};

export default Item;
