import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import App from './App';
import rootSaga from './store/rootSaga';
import { store, persistor, sagaMiddleware } from './store/createStore';
import { PersistGate } from 'redux-persist/integration/react';

sagaMiddleware.run(rootSaga);

const RootWrapper = () => (
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </ReduxProvider>
);

export default RootWrapper;
