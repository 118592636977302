import { subDays } from 'date-fns';
import Parse from 'parse';
import * as Sentry from '@sentry/browser';
import {
  all,
  fork,
  put,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { fetchTableOrder, switchRestaurant } from '../actions/menu';
import {
  ACTIONS,
  fetchOrders,
  fetchOrdersError,
  fetchOrdersSuccess,
  markOrdersSocketLoading,
  markOrdersSocketOpened,
  markTableOrderSocketLoading,
  markTableOrderSocketOpened,
  markAllOrdersFromTableOrderSocketLoading,
  markAllOrdersFromTableOrderSocketOpened,
  orderSentToRestaurant,
  paymentError,
  paymentInProcess,
  paymentSuccess,
  setPromoCodeValid,
  orderError,
} from '../actions/order';
import { fetchStripeUserSuccess } from '../actions/user';
import createLiveQueryChannel from './createChannel';
import watchCloseChannelMessage from './watchCloseChannelMessage';

// NOTE ON TABLEORDER STATES
// --------------------
// TableOrders collect all the orders in a table
// There are 4 states on tableOrders: pending, progress, completed, closed
// Pending: the table is open but orders have not been sent to the restaurant. Orders are sent to the restaurant when the number of sent orders matches the number of guests, or when a guest has manually requested the order to be sent to the restaurant (for example because one person at the table didn't show up)
// Progress: the table has been sent to the restaurant app
// Completed: all the orders have been served to the customers
// Closed: the bill has been paid and the table can be open for a new group

// NOTE ON ITEM STATES
// --------------------
// Items inside an order have 4 states: pending, progress, confirmed, completed
// Pending: items have not been sent to the restaurant
// Progress: items have been sent to the restaurant app and are waiting to be acknowledged
// Confirmed: the staff has entered the items into the restaurant POS system and they are being prepared
// Completed: the items have been served to the customers

// NOTE ON ORDER STATES
// --------------------
// Orders don't really need to have a state, because we already have a state on items and TableOrder, but it was done like this in the beginning, and it's still used as a shortcut to know if all users at a table have sent their order, and automatically mark the tableOrder state as "progress".
// Possible states are "pending" and "ordered"

// This saves pending items to the order but they don't appear on the restaurant app
function* saveOrderSaga(action) {
  const {
    item,
    number,
    comment,
    appetizerAsMainCourse,
    currentOrder,
  } = action.payload;
  try {
    let orderContent = currentOrder.get('order');
    if (
      orderContent.find(
        (obj) => obj.item.id === item.id && obj.state === 'pending',
      )
    ) {
      orderContent = orderContent.filter(
        (obj) => obj.item.id !== item.id || obj.state !== 'pending',
      );
    }
    const state = 'pending';
    const service = appetizerAsMainCourse ? 2 : item.defaultService;
    if (number > 0)
      orderContent.push({ item, number, comment, service, state });
    currentOrder.set('order', orderContent);
    yield currentOrder.save();
  } catch (error) {
    yield put(orderError(error));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.info('saveOrderSaga error', error);
  }
}

// This marks the items as ordered and automatically makes the order
// visible to the restaurant app if everyone on the table has ordered
function* sendOrderSaga(action) {
  console.info('sendOrderSaga');
  const { order, tableOrder } = action.payload;
  const orderContent = order.get('order');
  const orderCount = tableOrder.get('orderCount') || 0;
  const date = new Date().toUTCString();

  orderContent.forEach((item) => {
    if (item.state === 'pending') {
      item.state = 'progress';
      item.orderNumber = orderCount + 1;
      item.orderedAt = date;
    }
  });
  order.set('order', orderContent);
  order.set('state', 'ordered'); // We keep this as a shortcut to know if all users at a table have sent their order (see below)
  try {
    const allTableOrders = tableOrder.get('orders');
    const numberOfPeopleWhoOrdered = allTableOrders.filter(
      (order) => order.get('state') === 'ordered',
    ).length;

    // If table has already been sent to the kitchen once, or if this is the last guest to confirm their order,
    // we send this new order to the kitchen without further action from the guests
    if (
      tableOrder.get('state') !== 'pending' ||
      numberOfPeopleWhoOrdered >= tableOrder.get('minGuests')
    ) {
      tableOrder.set('state', 'progress');
      tableOrder.increment('orderCount');
      yield put(orderSentToRestaurant());
    }
    yield tableOrder.save();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('sendOrderSaga error', error);
    yield put(orderError(error));
    Sentry.captureException(error);
  }
}

function* sendTableSaga(action) {
  console.info('sendTableSaga');
  const { tableOrder } = action.payload;
  const orders = tableOrder.get('orders');
  const date = new Date().toUTCString();
  try {
    yield all(
      orders.map((order) => {
        const orderContent = order.get('order');
        orderContent.forEach((item) => {
          if (item.orderNumber === 1) {
            item.orderedAt = date;
          }
        });
        order.set('order', orderContent);
        return order.save();
      }),
    );

    tableOrder.set('state', 'progress');
    tableOrder.set('orderCount', 1);
    yield tableOrder.save();
    yield put(orderSentToRestaurant());
  } catch (error) {
    yield put(orderError(error));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.info('sendTableSaga error', error);
  }
}

function* savePaymentMethodSaga(action) {
  const { paymentMode, order } = action.payload;
  order.set('paymentMode', paymentMode);

  try {
    yield order.save();
  } catch (error) {
    yield put(orderError(error));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.info('savePaymentMethodSaga error', error);
  }
}

function* saveTipSaga(action) {
  const { tip, order } = action.payload;
  order.set('tip', tip / 100);

  try {
    yield order.save();
  } catch (error) {
    yield put(orderError(error));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.info('saveTipSaga error', error);
  }
}

function* initAllUserOrdersSocket(action) {
  const { userId } = action.payload;
  const Order = Parse.Object.extend('Order');
  const query = new Parse.Query(Order);
  query.equalTo('userId', userId);
  yield put(markOrdersSocketLoading());
  const subscription = yield query.subscribe();
  const channel = yield createLiveQueryChannel(
    subscription,
    null,
    () => fetchOrders(userId),
    null,
    () => fetchOrders(userId),
    'AllUserOrdersSocket',
  );
  yield put(markOrdersSocketOpened());
  yield fork(watchCloseChannelMessage, channel, 'AllUserOrdersSocket');
  try {
    while (true) {
      const action = yield take(channel);
      yield put(action);
    }
  } catch (e) {
    console.error('Error in OrdersSocket', e);
    Sentry.captureException(e);
  }
}

function* initTableOrderSocket(action) {
  const { restaurant, tableNumber } = action.payload;
  const TableOrder = Parse.Object.extend('TableOrder');
  // We subscribe to all the tableOrders with this tableNumber from the last 24h, because
  // otherwise we don't receive the info when the tableOrder is closed (and we need it to adjust the UI)
  const fromDate = subDays(Date.now(), 1);
  const query = new Parse.Query(TableOrder);
  query.equalTo('restaurant', restaurant);
  query.equalTo('tableNumber', tableNumber);
  query.greaterThan('createdAt', fromDate);
  yield put(markTableOrderSocketLoading());
  const subscription = yield query.subscribe();
  const channel = yield createLiveQueryChannel(
    subscription,
    null,
    () => fetchTableOrder(restaurant, tableNumber),
    switchRestaurant,
    () => fetchTableOrder(restaurant, tableNumber),
    'TableOrderSocket',
  );
  yield put(markTableOrderSocketOpened());
  yield fork(watchCloseChannelMessage, channel, 'TableOrderSocket');
  try {
    while (true) {
      const action = yield take(channel);
      yield put(action);
    }
  } catch (e) {
    console.error('Error in TableOrderSocket', e);
    Sentry.captureException(e);
  }
}

function* initAllOrdersFromTableOrderSocket(action) {
  const { tableOrder, restaurant, tableNumber } = action.payload;
  const Order = Parse.Object.extend('Order');
  const query = new Parse.Query(Order);
  query.equalTo('tableOrder', tableOrder);
  query.include('user');
  query.include('discountPointer');
  query.include('discountPointer.discount');
  query.include('refunds');
  yield put(markAllOrdersFromTableOrderSocketLoading());
  const subscription = yield query.subscribe();
  const channel = yield createLiveQueryChannel(
    subscription,
    null,
    () => fetchTableOrder(restaurant, tableNumber),
    null,
    () => fetchTableOrder(restaurant, tableNumber),
    'AllOrdersFromTableOrderSocket',
  );
  yield put(markAllOrdersFromTableOrderSocketOpened());
  yield fork(
    watchCloseChannelMessage,
    channel,
    'AllOrdersFromTableOrderSocket',
  );
  try {
    while (true) {
      const action = yield take(channel);
      yield put(action);
    }
  } catch (e) {
    console.error('Error in AllOrdersFromTableOrderSocket', e);
    Sentry.captureException(e);
  }
}

function* requestOrdersSaga(action) {
  const { userId } = action.payload;
  const Order = Parse.Object.extend('Order');
  const query = new Parse.Query(Order);
  query.limit(500); // TODO : this returns only a fixed number of items, would need pagination in the long run
  query.equalTo('userId', userId);
  query.include('restaurant');
  query.include('tableOrder');
  query.include('discountPointer');
  query.include('refunds');
  try {
    const orders = yield query.find();
    yield put(fetchOrdersSuccess(orders));
  } catch (error) {
    yield put(fetchOrdersError(error));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.info('requestOrdersSaga error', error);
  }
}

function* checkPromoCodeSaga(action) {
  const { promoCode, user, order } = action.payload;
  const Discount = Parse.Object.extend('Discount');
  const query = new Parse.Query(Discount);
  query.equalTo('code', promoCode.toLowerCase());
  query.equalTo('available', true);
  const [discount] = yield query.find();
  const userHasUsedDiscount = (user) => {
    const userDiscounts = user.get('discount');
    return userDiscounts ? userDiscounts.indexOf(discount.id) : null;
  };
  try {
    if (
      !discount ||
      !(discount.get('used') < discount.get('limit')) ||
      userHasUsedDiscount(user)
    ) {
      yield put(setPromoCodeValid(false));
    } else {
      discount.increment('used', 1);
      yield discount.save();

      order.set('discountPointer', discount);
      yield order.save();
      yield put(setPromoCodeValid(true));
    }
  } catch (error) {
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.info('checkPromoCodeSaga error', error);
  }
}

// TODO: handle multiple credit cards
function* payOrderSaga(action) {
  const { user, orderId, history } = action.payload;
  if (!user.get('stripeId')) {
    history.push('add-card');
  } else {
    yield put(paymentInProcess());
    try {
      const stripeUser = yield Parse.Cloud.run('retrieveStripeUser', {
        userId: user.id,
      });
      yield put(fetchStripeUserSuccess(stripeUser));
      if (!stripeUser.default_source) {
        history.push('add-card');
      } else {
        yield Parse.Cloud.run('payOrder', {
          modePayment: 0, // Pay by credit card
          orderId: orderId,
          userId: user.id,
        });
        yield put(paymentSuccess());
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('payOrderSaga error', error);
      yield put(paymentError(error));
      Sentry.captureException(error);
    }
  }
}

// TODO: this and above function are almost exactly identical, can be merged
// TODO: handle multiple credit cards
// TODO: handle errors (display to user)
function* payAllRemainingOrdersSaga(action) {
  const { user, tableOrderId, history } = action.payload;
  if (!user.get('stripeId')) {
    history.push('add-card');
  } else {
    yield put(paymentInProcess());
    try {
      const stripeUser = yield Parse.Cloud.run('retrieveStripeUser', {
        userId: user.id,
      });
      yield put(fetchStripeUserSuccess(stripeUser));
      if (!stripeUser.default_source) {
        history.push('add-card');
      } else {
        yield Parse.Cloud.run('payOrderForAll', {
          tableOrderId: tableOrderId,
          userId: user.id,
        });
        yield put(paymentSuccess());
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('payOrderSaga error', error);
      yield put(paymentError(error));
      Sentry.captureException(error);
    }
  }
}

function* saveReviewSaga(action) {
  const {
    userId,
    orderId,
    restaurantId,
    comment,
    reviews,
    waiterId,
  } = action.payload;
  yield Parse.Cloud.run('sendReview', {
    userId,
    orderId,
    restaurantId,
    comment,
    reviews,
    waiterId,
  });
}

export default function* () {
  yield takeEvery(ACTIONS.ADD_OR_UPDATE_ITEM_IN_ORDER, saveOrderSaga);
  yield takeLatest(ACTIONS.SEND_NEW_ITEMS_TO_RESTAURANT, sendOrderSaga);
  yield takeLatest(ACTIONS.SEND_TABLE_TO_RESTAURANT, sendTableSaga);
  yield takeLatest(ACTIONS.UPDATE_PAYMENT_METHOD, savePaymentMethodSaga);
  yield takeLatest(ACTIONS.UPDATE_TIP, saveTipSaga);
  yield takeLatest(ACTIONS.FETCH_ALL_CURRENT_USER_ORDERS, requestOrdersSaga);
  yield takeLatest(
    ACTIONS.OPEN_ALL_USER_ORDERS_SOCKET,
    initAllUserOrdersSocket,
  );
  yield takeLatest(ACTIONS.OPEN_TABLE_ORDER_SOCKET, initTableOrderSocket);
  yield takeLatest(
    ACTIONS.OPEN_ALL_ORDERS_FROM_TABLE_ORDER_SOCKET,
    initAllOrdersFromTableOrderSocket,
  );
  yield takeLatest(ACTIONS.CHECK_PROMO_CODE, checkPromoCodeSaga);
  yield takeLatest(ACTIONS.PAY_ORDER, payOrderSaga);
  yield takeLatest(ACTIONS.PAY_ALL_REMAINING_ORDERS, payAllRemainingOrdersSaga);
  yield takeLatest(ACTIONS.SAVE_REVIEW, saveReviewSaga);
}
