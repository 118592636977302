// @flow
export const ACTIONS = {
  OPEN_RESTAURANT_SOCKET: 'menu/openRestaurantSocket',
  RESTAURANT_SOCKET_LOADING: 'restaurant/restaurantSocketLoading',
  RESTAURANT_SOCKET_OPENED: 'restaurant/restaurantSocketOpened',

  REQUEST: 'menu/request',
  SUCCESS: 'menu/success',
  ERROR: 'menu/error',

  RESTAURANT_ID_AND_TABLE_SCANNED: 'menu/restaurantIdAndTableNumberScanned',

  TABLE_ORDER_REQUEST: 'menu/fetchTableOrderRequest',
  TABLE_ORDER_SUCCESS: 'menu/fetchTableOrderSuccess',
  TABLE_ORDER_ERROR: 'menu/fetchTableOrderError',
  FIRST_TABLE_ORDER_REQUEST: 'menu/firstFetchTableOrderRequest',

  UPDATED_ORDER_RECEIVED: 'menu/updatedOrderReceived',

  UPDATE_CURRENT_ORDER: 'menu/updateCurrentOrder',

  UPDATE_TABLE_ORDER: 'menu/updateTableOrder',

  CALL_WAITER: 'menu/callWaiter',
  WAITER_CALL_SENT: 'menu/setWaiterCallSent',

  SWITCH_RESTAURANT: 'menu/switchRestaurant',

  TABLE_ORDER_CLOSED: 'menu/tableOrderIsClosed',
  RESET_TABLE_ORDER_CLOSED: 'menu/resetTableOrderClosed',
};

export const openRestaurantSocket = (restaurantId: string): Object => ({
  type: ACTIONS.OPEN_RESTAURANT_SOCKET,
  payload: { restaurantId },
});

export const fetchRestaurant = (restaurantId: string): Object => ({
  type: ACTIONS.REQUEST,
  payload: { restaurantId },
});

export const fetchRestaurantSuccess = (restaurant: Object): Object => ({
  type: ACTIONS.SUCCESS,
  payload: { restaurant },
});

export const fetchRestaurantError = (error: Object): Object => ({
  type: ACTIONS.ERROR,
  payload: { error },
});

export const fetchTableOrder = (
  restaurant: Object,
  tableNumber: string,
): Object => ({
  type: ACTIONS.TABLE_ORDER_REQUEST,
  payload: { restaurant, tableNumber },
});

export const firstFetchTableOrder = (
  restaurant: Object,
  tableNumber: string,
  user: Object,
): Object => ({
  type: ACTIONS.FIRST_TABLE_ORDER_REQUEST,
  payload: { restaurant, tableNumber, user },
});

export const fetchTableOrderSuccess = (tableOrder: Object): Object => ({
  type: ACTIONS.TABLE_ORDER_SUCCESS,
  payload: { tableOrder },
});

export const fetchTableOrderError = (error: Object): Object => ({
  type: ACTIONS.TABLE_ORDER_ERROR,
  payload: { error },
});

export const tableOrderIsClosed = (): Object => ({
  type: ACTIONS.TABLE_ORDER_CLOSED,
});

export const resetTableOrderIsClosed = (): Object => ({
  type: ACTIONS.RESET_TABLE_ORDER_CLOSED,
});

export const restaurantIdAndTableNumberScanned = (
  restaurantId: string,
  tableNumber: string,
): Object => ({
  type: ACTIONS.RESTAURANT_ID_AND_TABLE_SCANNED,
  payload: { restaurantId, tableNumber },
});

export const updateCurrentOrder = (order: Object): Object => ({
  type: ACTIONS.UPDATE_CURRENT_ORDER,
  payload: order,
});

export const updateTableOrder = (tableOrder: Object): Object => ({
  type: ACTIONS.UPDATE_TABLE_ORDER,
  payload: tableOrder,
});

export const callWaiter = (
  user: Object,
  tableNumber: number,
  restaurant: Object,
  type: string,
): Object => ({
  type: ACTIONS.CALL_WAITER,
  payload: { user, tableNumber, restaurant, type },
});

export const setWaiterCallSent = (bool: boolean): Object => ({
  type: ACTIONS.WAITER_CALL_SENT,
  payload: bool,
});

export const switchRestaurant = (): Object => ({
  type: ACTIONS.SWITCH_RESTAURANT,
});

export const markRestaurantSocketLoading = (): Object => ({
  type: ACTIONS.RESTAURANT_SOCKET_LOADING,
});

export const markRestaurantSocketOpened = (): Object => ({
  type: ACTIONS.RESTAURANT_SOCKET_OPENED,
});
