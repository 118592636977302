import Parse from 'parse';
import * as Sentry from '@sentry/browser';
import { fork, put, take, takeLatest } from 'redux-saga/effects';
import {
  ACTIONS,
  fetchVersionError,
  fetchVersionSuccess,
  markVersionSocketLoading,
  markVersionSocketOpened,
} from '../actions/version';
import watchCloseChannelMessage from './watchCloseChannelMessage';
import createLiveQueryChannel from './createChannel';

function* requestVersionSaga() {
  try {
    const Version = new Parse.Query('Version');
    Version.equalTo('platform', 'client');
    const version = yield Version.first();
    yield put(fetchVersionSuccess(version));
  } catch (error) {
    yield put(fetchVersionError(error));
    Sentry.captureException(error);
    // eslint-disable-next-line no-console
    console.info('requestVersionSaga error', error);
  }
}

function* initVersionSocket() {
  const Version = Parse.Object.extend('Version');
  const query = new Parse.Query(Version);
  query.equalTo('platform', 'client');
  yield put(markVersionSocketLoading());
  const subscription = yield query.subscribe();
  const channel = yield createLiveQueryChannel(
    subscription,
    null,
    fetchVersionSuccess,
    null,
    null,
    'VersionSocket',
  );
  yield put(markVersionSocketOpened());
  yield fork(watchCloseChannelMessage, channel);
  try {
    while (true) {
      const action = yield take(channel);
      yield put(action);
    }
  } catch (e) {
    console.error('Error in VersionSocket', e);
    Sentry.captureException(e);
  }
}

export default function* () {
  yield takeLatest(ACTIONS.REQUEST_VERSION, requestVersionSaga);
  yield takeLatest(ACTIONS.OPEN_VERSION_SOCKET, initVersionSocket);
}
