import produce from 'immer';
import { ACTIONS as ORDER_ACTIONS } from '../actions/order';
import { ACTIONS as VERSION_ACTIONS } from '../actions/version';
import { ACTIONS as RESTAURANT_ACTIONS } from '../actions/menu';
import { ACTIONS as USER_ACTIONS } from '../actions/user';

const initialState = {
  versionSocketLoading: false,
  versionSocketOpened: false,
  allUserOrdersSocketOpened: false,
  allUserOrdersSocketLoading: false,
  tableOrderSocketLoading: false,
  tableOrderSocketOpened: false,
  allOrdersFromTableOrderSocketLoading: false,
  allOrdersFromTableOrderSocketOpened: false,
  restaurantSocketLoading: false,
  restaurantSocketOpened: false,
};

const reducer = produce((draft, { type }) => {
  switch (type) {
    case VERSION_ACTIONS.VERSION_SOCKET_LOADING:
      draft.versionSocketLoading = true;
      draft.versionSocketOpened = false;
      break;
    case VERSION_ACTIONS.VERSION_SOCKET_OPENED:
      draft.versionSocketLoading = false;
      draft.versionSocketOpened = true;
      break;
    case ORDER_ACTIONS.ALL_USER_ORDERS_SOCKET_LOADING:
      draft.allUserOrdersSocketLoading = true;
      draft.allUserOrdersSocketOpened = false;
      break;
    case ORDER_ACTIONS.ALL_USER_ORDERS_SOCKET_OPENED:
      draft.allUserOrdersSocketOpened = true;
      draft.allUserOrdersSocketLoading = false;
      break;
    case ORDER_ACTIONS.TABLE_ORDER_SOCKET_LOADING:
      draft.tableOrderSocketOpened = false;
      draft.tableOrderSocketLoading = true;
      break;
    case ORDER_ACTIONS.TABLE_ORDER_SOCKET_OPENED:
      draft.tableOrderSocketOpened = true;
      draft.tableOrderSocketLoading = false;
      break;
    case ORDER_ACTIONS.ALL_ORDERS_FROM_TABLE_ORDER_SOCKET_LOADING:
      draft.allOrdersFromTableOrderSocketOpened = false;
      draft.allOrdersFromTableOrderSocketLoading = true;
      break;
    case ORDER_ACTIONS.ALL_ORDERS_FROM_TABLE_ORDER_SOCKET_OPENED:
      draft.allOrdersFromTableOrderSocketOpened = true;
      draft.allOrdersFromTableOrderSocketLoading = false;
      break;
    case RESTAURANT_ACTIONS.RESTAURANT_SOCKET_LOADING:
      draft.restaurantSocketLoading = true;
      draft.restaurantSocketOpened = false;
      break;
    case RESTAURANT_ACTIONS.RESTAURANT_SOCKET_OPENED:
      draft.restaurantSocketLoading = false;
      draft.restaurantSocketOpened = true;
      break;
    case RESTAURANT_ACTIONS.SWITCH_RESTAURANT:
      draft.restaurantSocketLoading = false;
      draft.restaurantSocketOpened = false;
      draft.tableOrderSocketOpened = false;
      draft.tableOrderSocketLoading = false;
      draft.allOrdersFromTableOrderSocketOpened = false;
      draft.allOrdersFromTableOrderSocketLoading = false;
      break;
    case USER_ACTIONS.LOGOUT:
      draft.restaurantSocketLoading = false;
      draft.restaurantSocketOpened = false;
      draft.tableOrderSocketOpened = false;
      draft.tableOrderSocketLoading = false;
      draft.allOrdersFromTableOrderSocketOpened = false;
      draft.allOrdersFromTableOrderSocketLoading = false;
      draft.allUserOrdersSocketOpened = false;
      draft.allUserOrdersSocketLoading = false;
      break;
    default:
      break;
  }
}, initialState);

export default reducer;

export const selectors = {
  version: (state) => state.socket.version,
  versionSocketLoading: (state) => state.socket.versionSocketLoading,
  versionSocketOpened: (state) => state.socket.versionSocketOpened,
  allUserOrdersSocketOpened: (state) => state.socket.allUserOrdersSocketOpened,
  allUserOrdersSocketLoading: (state) =>
    state.socket.allUserOrdersSocketLoading,
  tableOrderSocketOpened: (state) => state.socket.tableOrderSocketOpened,
  tableOrderSocketLoading: (state) => state.socket.tableOrderSocketLoading,
  allOrdersFromTableOrderSocketOpened: (state) =>
    state.socket.allOrdersFromTableOrderSocketOpened,
  allOrdersFromTableOrderSocketLoading: (state) =>
    state.socket.allOrdersFromTableOrderSocketLoading,
  restaurantSocketLoading: (state) => state.socket.restaurantSocketLoading,
  restaurantSocketOpened: (state) => state.socket.restaurantSocketOpened,
};
