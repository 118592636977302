// @flow
import React, { type Node } from 'react';
import { Button, makeStyles } from '@material-ui/core';

type Props = {
  fullWidth?: boolean,
  color?: string,
  onClick?: Function,
  outlined?: boolean,
  type?: string,
  style?: Object,
  className?: Object,
  disabled?: boolean,
  children: string,
};

const RoundButton = ({
  children,
  fullWidth,
  color,
  onClick,
  outlined,
  type,
  style,
  className,
  disabled,
}: Props): Node => {
  const classes = useStyles();

  return (
    <Button
      classes={{ root: classes.buttonRoot }}
      variant={outlined ? 'outlined' : 'contained'}
      disableElevation
      fullWidth={fullWidth}
      color={color}
      onClick={onClick}
      type={type}
      style={style}
      className={className}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

const useStyles = makeStyles({
  buttonRoot: {
    borderRadius: '18px',
    textTransform: 'capitalize',
  },
});

export default RoundButton;
