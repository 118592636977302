// @flow
import {
  Box,
  CardMedia,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import i18next from 'i18next';
import React, { type Node, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import {
  fetchRestaurant,
  firstFetchTableOrder,
  openRestaurantSocket,
  restaurantIdAndTableNumberScanned,
} from '../actions/menu';
import {
  addOrUpdateItemInOrder,
  openTableOrderSocket,
  openAllOrdersFromTableOrderSocket,
} from '../actions/order';
import Header from '../components/Header';
import PopupDialog from '../components/PopupDialog';
import RoundButton from '../components/RoundButton';
import { selectors as menuSelectors } from '../reducers/menu';
import { selectors as ordersSelectors } from '../reducers/order';
import { selectors as socketSelectors } from '../reducers/socket';
import { selectors as userSelectors } from '../reducers/user';
import formatMoney from '../utils/formatMoney';
import QrCode from '../components/QrCode';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    position: 'relative',
    height: 250,
    flexShrink: 0,
    flexGrow: 0,
  },
  menu: {
    position: 'relative',
    overflow: 'scroll',
    marginTop: 10,
    padding: 20,
    paddingTop: 0,
  },
  coverPhoto: {
    height: 200,
  },
  restaurantInfo: {
    position: 'absolute',
    bottom: 20,
    left: 20,
  },
  name: {
    fontWeight: 'bold',
    fontSize: 30,
  },
  ratingBox: {
    display: 'flex',
    flexDirection: 'row',
  },
  callWaiter: {
    position: 'absolute',
    right: 20,
    top: 20,
  },
  white: {
    color: 'white',
  },
  tabs: {
    display: 'flex',
    padding: 20,
    overflow: 'scroll',
  },
  section: {
    marginBottom: 70,
  },
  sectionLink: {
    paddingRight: 20,
    whiteSpace: 'nowrap',
    '&:hover': {
      color: 'red',
    },
  },
  menuItem: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    marginBottom: 30,
  },
  menuItemName: {
    flexShrink: 1,
    flexGrow: 1,
    marginRight: 20,
    marginBottom: 20,
  },
  menuItemText: {
    flexShrink: 1,
    flexGrow: 1,
    marginRight: 20,
  },
  menuItemTextModal: {
    flexShrink: 1,
    flexGrow: 1,
    marginTop: 20,
    textAlign: 'center',
    padding: 20,
  },
  prices: {
    display: 'flex',
    marginTop: 3,
  },
  pricesModal: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
  },
  price: {
    color: 'red',
    fontWeight: 'bold',
  },
  priceBarred: {
    color: 'red',
    fontWeight: 'bold',
    textDecoration: 'line-through',
  },
  menuItemPhoto: {
    flexShrink: 0,
    flexGrow: 0,
    width: 80,
    height: 80,
    borderRadius: 15,
  },
  numberOfItems: {
    position: 'absolute',
    top: 0,
    right: 60,
    backgroundColor: 'red',
    borderRadius: 30,
    width: 25,
    textAlign: 'center',
  },
  modalContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  modalPhoto: {
    height: 200,
  },
  closeModal: {
    position: 'absolute',
    top: 10,
    right: 20,
  },
  addToOrder: {
    position: 'absolute',
    width: 'calc(100% - 40px)',
    bottom: 0,
    margin: 20,
  },
  chooseService: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    marginBottom: 50,
  },
  quantityInstructionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    flexGrow: 1,
    marginBottom: 50,
  },
  quantity: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  quantityButton: {
    padding: 10,
    borderRadius: 30,
    backgroundColor: 'red',
    margin: 10,
    width: 10,
    textAlign: 'center',
  },
  quantityButtonText: {
    color: 'white',
    lineHeight: '10px',
  },
  instructions: {
    padding: 20,
    width: '100%',
    flexGrow: 1,
    boxSizing: 'border-box',
  },
  instructionsField: {
    width: '100%',
  },
  viewOrder: {
    position: 'fixed',
    width: 'calc(100% - 40px)',
    bottom: 20,
    left: 20,
  },
  modalTitle: {
    padding: 20,
    fontWeight: 'bold',
  },
  modalButtons: {
    position: 'absolute',
    bottom: 20,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalCancel: {
    marginRight: 20,
  },
  modalConfirm: {
    color: 'white',
  },
});

// TODO: split this class, it's too long
const Menu = (): Node => {
  const { restaurantIdAndTable } = useParams();
  const dispatch = useDispatch();
  const loading = useSelector(menuSelectors.isLoading);
  const restaurantId = useSelector(menuSelectors.restaurantId);
  const tableNumber = useSelector(menuSelectors.tableNumber);
  const restaurant = useSelector(menuSelectors.restaurant);
  const restaurantSocketLoading = useSelector(
    socketSelectors.restaurantSocketLoading,
  );
  const restaurantSocketOpened = useSelector(
    socketSelectors.restaurantSocketOpened,
  );
  const menuError = useSelector(menuSelectors.error);
  const tableOrder = useSelector(menuSelectors.tableOrder);
  const tableOrderError = useSelector(menuSelectors.tableOrderError);
  const tableOrderSocketOpened = useSelector(
    socketSelectors.tableOrderSocketOpened,
  );
  const tableOrderSocketLoading = useSelector(
    socketSelectors.tableOrderSocketLoading,
  );
  const allOrdersFromTableOrderSocketOpened = useSelector(
    socketSelectors.allOrdersFromTableOrderSocketOpened,
  );
  const allOrdersFromTableOrderSocketLoading = useSelector(
    socketSelectors.allOrdersFromTableOrderSocketLoading,
  );
  const currentOrder = useSelector(ordersSelectors.currentOrder);
  const user = useSelector(userSelectors.user);
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const locale = restaurant ? restaurant.get('country') : null;
  const currency = restaurant ? restaurant.get('currency') : null;

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [itemInModal, setItemInModal] = React.useState(null);
  const [quantityItemInModal, setQuantityItemInModal] = React.useState(1);
  const [comment, setComment] = React.useState('');
  const [currentItemMainCourse, setCurrentItemMainCourse] = React.useState(
    false,
  );
  const [quantityModalIsOpen, setQuantityModalIsOpen] = React.useState(false);

  function openModal(item) {
    setItemInModal(item);
    const existingItem = currentOrder
      .get('order')
      .find((order) => order.item.id === item.id && order.state === 'pending');
    if (existingItem) {
      setQuantityItemInModal(existingItem.number);
    }
    setIsOpen(true);
  }

  function closeModal() {
    setItemInModal(null);
    setQuantityItemInModal(1);
    setIsOpen(false);
  }

  // When we initially receive restaurantId and tableNumber
  useEffect(() => {
    if (restaurantIdAndTable) {
      const [restaurantId, tableNumber] = restaurantIdAndTable.split('-');
      dispatch(restaurantIdAndTableNumberScanned(restaurantId, tableNumber));
    }
  }, [restaurantIdAndTable]);

  // Try to open the restaurant socket if it isn't already
  useEffect(() => {
    if (restaurantId && !restaurantSocketLoading && !restaurantSocketOpened) {
      dispatch(openRestaurantSocket(restaurantId));
    }
  }, [restaurantId, restaurantSocketLoading, restaurantSocketOpened]);

  // Try to open the tableOrder socket if it isn't already
  useEffect(() => {
    if (
      restaurant &&
      tableNumber &&
      !tableOrderSocketLoading &&
      !tableOrderSocketOpened
    ) {
      dispatch(openTableOrderSocket(restaurant, tableNumber));
    }
  }, [restaurant, tableNumber]);

  // Try to open the allOrdersFromTableOrder socket if it isn't already
  useEffect(() => {
    if (
      tableOrder &&
      !allOrdersFromTableOrderSocketLoading &&
      !allOrdersFromTableOrderSocketOpened
    ) {
      dispatch(
        openAllOrdersFromTableOrderSocket(tableOrder, restaurant, tableNumber),
      );
    }
  }, [restaurant, tableNumber, tableOrder]);

  // Fetch the restaurant on first load or when params change
  useEffect(() => {
    if (restaurantId && (!restaurant || restaurant.id !== restaurantId)) {
      dispatch(fetchRestaurant(restaurantId));
    }
  }, [restaurantId]);

  // Fetch the tableOrder on first load or when params change
  useEffect(() => {
    if (restaurant && tableNumber && user && !tableOrder) {
      dispatch(firstFetchTableOrder(restaurant, tableNumber, user));
    }
  }, [restaurantId, restaurant, tableNumber, tableOrder]);

  // If the order is paid we don't want to display the menu (the user can't order anymore) so we send them to the Order page
  useEffect(() => {
    if (currentOrder && currentOrder.get('paid') === true) {
      history.push('/order');
    }
  });

  useEffect(() => {
    if (menuError) {
      addToast(i18next.t('toasts.error'), { appearance: 'error' });
    }
  }, [menuError]);

  const modalStyles = {
    content: {
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      padding: 0,
      border: 0,
    },
  };

  const addItemToOrder = (item, number, comment, appetizerAsMainCourse) => {
    dispatch(
      addOrUpdateItemInOrder(
        item,
        number,
        comment,
        appetizerAsMainCourse,
        currentOrder,
      ),
    );
    setComment('');
    setCurrentItemMainCourse(false);
    closeModal();
  };

  const renderMenuItemText = (item, isModal) => (
    <Box className={isModal ? classes.menuItemTextModal : classes.menuItemText}>
      <Typography variant={isModal ? 'h5' : 'h6'} color={'textPrimary'}>
        {item.name}
      </Typography>
      <Typography variant={isModal ? 'body1' : 'body2'} color={'textPrimary'}>
        {item.description}
      </Typography>
      <Box className={isModal ? classes.pricesModal : classes.prices}>
        <Typography
          variant={isModal ? 'body1' : 'body2'}
          color={'textPrimary'}
          className={
            item.deal !== undefined ? classes.priceBarred : classes.price
          }
        >
          {formatMoney(item.price, locale, currency)}
        </Typography>
        {item.deal !== undefined && (
          <Typography
            variant={isModal ? 'body1' : 'body2'}
            color={'textPrimary'}
            className={classes.price}
          >
            &nbsp;
            {formatMoney(item.price - (item.deal || 0), locale, currency)}
          </Typography>
        )}
      </Box>
    </Box>
  );

  const onClickAdd = () => {
    quantityItemInModal === 1
      ? setQuantityModalIsOpen(true)
      : setQuantityItemInModal(quantityItemInModal + 1);
  };

  const renderItemModal = () => (
    <Modal isOpen={modalIsOpen} style={modalStyles}>
      {itemInModal && (
        <Box className={classes.modalContainer}>
          {itemInModal.coverPhoto && (
            <CardMedia
              className={classes.modalPhoto}
              image={itemInModal.coverPhoto.url()}
              title="Item photo"
            />
          )}
          <Box className={classes.closeModal} onClick={closeModal}>
            <Typography variant="h4" color="secondary">
              x
            </Typography>
          </Box>
          {renderMenuItemText(itemInModal, true)}
          {tableOrder.get('state') === 'pending' &&
            itemInModal.defaultService === 1 && (
              <Box className={classes.chooseService}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={currentItemMainCourse}
                      onChange={() =>
                        setCurrentItemMainCourse(!currentItemMainCourse)
                      }
                      name="service"
                      color="primary"
                    />
                  }
                  label={t('containers.Menu.orderAsMain')}
                />
              </Box>
            )}
          <Box className={classes.quantityInstructionsContainer}>
            <Box className={classes.quantity}>
              <Box
                className={classes.quantityButton}
                onClick={() => {
                  if (quantityItemInModal > 0) {
                    setQuantityItemInModal(quantityItemInModal - 1);
                  }
                }}
              >
                <Typography className={classes.quantityButtonText}>
                  -
                </Typography>
              </Box>
              <Typography variant="h6">{quantityItemInModal}</Typography>
              <Box className={classes.quantityButton} onClick={onClickAdd}>
                <Typography className={classes.quantityButtonText}>
                  +
                </Typography>
              </Box>
            </Box>
            <Box className={classes.instructions}>
              <TextField
                id="outlined-basic"
                label={t('common.comment')}
                variant="outlined"
                className={classes.instructionsField}
                value={comment}
                onChange={(event) => {
                  setComment(event.target.value);
                }}
              />
            </Box>
          </Box>
          <Box className={classes.addToOrder}>
            <RoundButton
              fullWidth
              color="primary"
              onClick={() =>
                addItemToOrder(
                  itemInModal,
                  quantityItemInModal,
                  comment,
                  currentItemMainCourse,
                )
              }
            >
              {quantityItemInModal === 0
                ? t('containers.Menu.removeFromOrder')
                : t('containers.Menu.addToOrder', {
                    quantity: quantityItemInModal,
                    amount: Number.isInteger(
                      (itemInModal.price - (itemInModal.deal || 0)) *
                        quantityItemInModal,
                    )
                      ? formatMoney(
                          (itemInModal.price - (itemInModal.deal || 0)) *
                            quantityItemInModal,
                          locale,
                          currency,
                          0,
                        )
                      : formatMoney(
                          (itemInModal.price - (itemInModal.deal || 0)) *
                            quantityItemInModal,
                          locale,
                          currency,
                          2,
                        ),
                  })}
            </RoundButton>
          </Box>
        </Box>
      )}
    </Modal>
  );

  const renderQuantityModal = () => (
    <PopupDialog isOpen={quantityModalIsOpen} height={200}>
      <Typography className={classes.modalTitle}>{t('common.sure')}</Typography>
      <Typography className={classes.modalContent}>
        {t('containers.Menu.multipleItems')}
      </Typography>
      <Box className={classes.modalButtons}>
        <RoundButton
          className={classes.modalCancel}
          onClick={() => setQuantityModalIsOpen(false)}
        >
          {t('common.no')}
        </RoundButton>
        <RoundButton
          className={classes.modalConfirm}
          color="primary"
          onClick={() => {
            setQuantityItemInModal(quantityItemInModal + 1);
            setQuantityModalIsOpen(false);
          }}
        >
          {t('common.yes')}
        </RoundButton>
      </Box>
    </PopupDialog>
  );

  const ifNotPaidOpenModal = (item) => {
    if (!currentOrder.get('paid')) openModal(item);
  };

  const renderSection = (section, orderObject) => {
    const order = orderObject.get('order');
    const pendingItems = (item) =>
      order.find(
        (order) => order.item.id === item.id && order.state === 'pending',
      );
    return (
      <Box key={section.id} id={section.name} className={classes.section}>
        <Typography variant={'h5'} className={classes.menuItemName}>
          {section.name}
        </Typography>
        {section.menu
          .filter((item) => item.available === true)
          .map((item) => (
            <Link onClick={() => ifNotPaidOpenModal(item)} key={item.id}>
              <Box className={classes.menuItem}>
                {renderMenuItemText(item)}
                {item.coverPhoto && (
                  <CardMedia
                    className={classes.menuItemPhoto}
                    image={item.coverPhoto.url()}
                    title="Item photo"
                  />
                )}
                {pendingItems(item) && (
                  <Box className={classes.numberOfItems}>
                    <Typography color="secondary">
                      {pendingItems(item).number}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Link>
          ))}
      </Box>
    );
  };

  return (
    <>
      {!restaurantId || !tableNumber || tableOrderError ? (
        <QrCode />
      ) : loading || !restaurant || !currentOrder ? (
        <Container className={classes.loadingContainer}>
          <CircularProgress />
        </Container>
      ) : (
        <Container className={classes.container}>
          <Header restaurant={restaurant} />
          <Box className={classes.tabs}>
            {restaurant
              .get('menu')
              .filter((section) =>
                section.menu.find((item) => item.available === true),
              )
              .map((section) => (
                <Box key={section.id}>
                  <Link
                    className={classes.sectionLink}
                    href={`#${section.name}`}
                    color="inherit"
                    variant={'body2'}
                  >
                    {section.name}
                  </Link>
                </Box>
              ))}
          </Box>
          <Box className={classes.menu}>
            {restaurant
              .get('menu')
              .filter((section) =>
                section.menu.find((item) => item.available === true),
              )
              .map((section) => renderSection(section, currentOrder))}
            <RoundButton
              className={classes.viewOrder}
              fullWidth
              color="primary"
              onClick={() => {
                history.push('/order');
              }}
            >
              {currentOrder
                .get('order')
                .filter((order) => order.state === 'pending').length === 0
                ? t('containers.Menu.viewOrder')
                : t('containers.Menu.viewOrderWithDetails', {
                    pending: currentOrder
                      .get('order')
                      .find((order) => order.state === 'pending')
                      ? t('containers.Menu.pending')
                      : '',
                    quantity: currentOrder
                      .get('order')
                      .filter((order) => order.state === 'pending')
                      .reduce(
                        (accumulator, current) => accumulator + current.number,
                        0,
                      ),
                    price: formatMoney(
                      currentOrder
                        .get('order')
                        .filter((order) => order.state === 'pending')
                        .reduce(
                          (accumulator, current) =>
                            Number(
                              accumulator +
                                current.number *
                                  (current.item.price -
                                    (current.item.deal || 0)),
                            ),
                          0,
                        ),
                      locale,
                      currency,
                    ),
                  })}
            </RoundButton>
          </Box>
          {renderItemModal()}
          {renderQuantityModal()}
        </Container>
      )}
    </>
  );
};
export default Menu;
