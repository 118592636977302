// @flow
export const ACTIONS = {
  ADD_OR_UPDATE_ITEM_IN_ORDER: 'order/addOrUpdateItemInOrder',

  UPDATE_PAYMENT_METHOD: 'order/updatePaymentMethod',

  UPDATE_TIP: 'order/updateTip',

  OPEN_ALL_USER_ORDERS_SOCKET: 'order/openAllUserOrdersSocket',
  ALL_USER_ORDERS_SOCKET_LOADING: 'order/allUserOrdersSocketLoading',
  ALL_USER_ORDERS_SOCKET_OPENED: 'order/allUserOrdersSocketOpened',

  OPEN_TABLE_ORDER_SOCKET: 'menu/openTableOrderSocket',
  TABLE_ORDER_SOCKET_LOADING: 'menu/tableOrderSocketLoading',
  TABLE_ORDER_SOCKET_OPENED: 'menu/tableOrderSocketOpened',

  OPEN_ALL_ORDERS_FROM_TABLE_ORDER_SOCKET: 'menu/allOrdersFromTableOrderSocket',
  ALL_ORDERS_FROM_TABLE_ORDER_SOCKET_LOADING:
    'menu/allOrdersFromTableOrderSocketLoading',
  ALL_ORDERS_FROM_TABLE_ORDER_SOCKET_OPENED:
    'menu/allOrdersFromTableOrderSocketOpened',

  SEND_NEW_ITEMS_TO_RESTAURANT: 'order/sendNewItemsToRestaurant',
  SEND_TABLE_TO_RESTAURANT: 'order/sendTableToRestaurant',

  FETCH_ALL_CURRENT_USER_ORDERS: 'order/fetchAllCurrentUserOrders',
  FETCH_ALL_CURRENT_USER_ORDERS_SUCCESS:
    'order/fetchAllCurrentUserOrdersSuccess',
  FETCH_ALL_CURRENT_USER_ORDERS_ERROR: 'order/fetchAllCurrentUserOrdersError',

  CHECK_PROMO_CODE: 'order/checkPromoCode',
  SET_PROMO_CODE_VALID: 'order/setPromoCodeValid',

  PAY_ORDER: 'order/payOrder',
  PAY_ALL_REMAINING_ORDERS: 'order/payAllRemainingOrders',
  PAYMENT_IN_PROCESS: 'order/paymentInProcess',
  PAYMENT_SUCCESS: 'order/paymentSuccess',
  PAYMENT_ERROR: 'order/paymentError',

  ORDER_SENT_TO_RESTAURANT: 'order/orderSentToRestaurant',

  HIDE_ORDER_SENT_TO_RESTAURANT_POPUP: 'order/hideOrderSentToRestaurantPopup',

  SAVE_REVIEW: 'order/saveReview',

  ORDER_ERROR: 'order/error',
};

export const openAllUserOrdersSocket = (userId: string): Object => ({
  type: ACTIONS.OPEN_ALL_USER_ORDERS_SOCKET,
  payload: { userId },
});

export const openTableOrderSocket = (
  restaurant: Object,
  tableNumber: number,
): Object => ({
  type: ACTIONS.OPEN_TABLE_ORDER_SOCKET,
  payload: { restaurant, tableNumber },
});

export const openAllOrdersFromTableOrderSocket = (
  tableOrder: Object,
  restaurant: Object,
  tableNumber: string,
): Object => ({
  type: ACTIONS.OPEN_ALL_ORDERS_FROM_TABLE_ORDER_SOCKET,
  payload: { tableOrder, restaurant, tableNumber },
});

export const addOrUpdateItemInOrder = (
  item: Object,
  number: Number,
  comment: String,
  appetizerAsMainCourse: boolean,
  currentOrder: Object,
): Object => ({
  type: ACTIONS.ADD_OR_UPDATE_ITEM_IN_ORDER,
  payload: { item, number, comment, appetizerAsMainCourse, currentOrder },
});

export const sendNewItemsToRestaurant = (
  order: Object,
  tableOrder: Object,
): Object => ({
  type: ACTIONS.SEND_NEW_ITEMS_TO_RESTAURANT,
  payload: { order, tableOrder },
});

export const sendTableToRestaurant = (tableOrder: Object): Object => ({
  type: ACTIONS.SEND_TABLE_TO_RESTAURANT,
  payload: { tableOrder },
});

export const setPaymentMethod = (
  paymentMode: String,
  order: Object,
): Object => ({
  type: ACTIONS.UPDATE_PAYMENT_METHOD,
  payload: { paymentMode, order },
});

export const updateTip = (tip: number, order: Object): Object => ({
  type: ACTIONS.UPDATE_TIP,
  payload: { tip, order },
});

export const markOrdersSocketOpened = (): Object => ({
  type: ACTIONS.ALL_USER_ORDERS_SOCKET_OPENED,
});

export const markOrdersSocketLoading = (): Object => ({
  type: ACTIONS.ALL_USER_ORDERS_SOCKET_LOADING,
});

export const markTableOrderSocketOpened = (): Object => ({
  type: ACTIONS.TABLE_ORDER_SOCKET_OPENED,
});

export const markTableOrderSocketLoading = (): Object => ({
  type: ACTIONS.TABLE_ORDER_SOCKET_LOADING,
});

export const markAllOrdersFromTableOrderSocketOpened = (): Object => ({
  type: ACTIONS.ALL_ORDERS_FROM_TABLE_ORDER_SOCKET_OPENED,
});

export const markAllOrdersFromTableOrderSocketLoading = (): Object => ({
  type: ACTIONS.ALL_ORDERS_FROM_TABLE_ORDER_SOCKET_LOADING,
});

export const fetchOrders = (userId: string): Object => ({
  type: ACTIONS.FETCH_ALL_CURRENT_USER_ORDERS,
  payload: { userId },
});

export const fetchOrdersSuccess = (orders: Object): Object => ({
  type: ACTIONS.FETCH_ALL_CURRENT_USER_ORDERS_SUCCESS,
  payload: { orders },
});

export const fetchOrdersError = (error: Object): Object => ({
  type: ACTIONS.FETCH_ALL_CURRENT_USER_ORDERS_ERROR,
  payload: { error },
});

export const checkPromoCode = (
  promoCode: string,
  user: Object,
  order: Object,
): Object => ({
  type: ACTIONS.CHECK_PROMO_CODE,
  payload: { promoCode, user, order },
});
export const setPromoCodeValid = (bool: boolean): Object => ({
  type: ACTIONS.SET_PROMO_CODE_VALID,
  payload: bool,
});

export const payOrder = (
  user: Object,
  orderId: string,
  history: Object,
): Object => ({
  type: ACTIONS.PAY_ORDER,
  payload: { user, orderId, history },
});

export const payAllRemainingOrders = (
  user: Object,
  tableOrderId: string,
  history: Object,
): Object => ({
  type: ACTIONS.PAY_ALL_REMAINING_ORDERS,
  payload: { user, tableOrderId, history },
});

export const paymentInProcess = (): Object => ({
  type: ACTIONS.PAYMENT_IN_PROCESS,
});

export const paymentSuccess = (): Object => ({
  type: ACTIONS.PAYMENT_SUCCESS,
});

export const paymentError = (error): Object => ({
  type: ACTIONS.PAYMENT_ERROR,
  payload: { error },
});

export const orderSentToRestaurant = () => ({
  type: ACTIONS.ORDER_SENT_TO_RESTAURANT,
});

export const hideOrderSentToRestaurantPopup = () => ({
  type: ACTIONS.HIDE_ORDER_SENT_TO_RESTAURANT_POPUP,
});

export const saveReview = (
  userId: string,
  orderId: string,
  restaurantId: string,
  comment: string,
  reviews: Object,
  waiterId: string,
): Object => ({
  type: ACTIONS.SAVE_REVIEW,
  payload: { userId, orderId, restaurantId, comment, reviews, waiterId },
});

export const orderError = (error): Object => ({
  type: ACTIONS.ORDER_ERROR,
  payload: { error },
});
