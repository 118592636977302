// @flow
import { Box, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { type Node, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Icon from '../components/Icon';
import RoundButton from '../components/RoundButton';
import mastercard from '../icons/mastercard.svg';
import visa from '../icons/visa.svg';
import { selectors as ordersSelectors } from '../reducers/order';
import { selectors as userSelectors } from '../reducers/user';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    padding: 20,
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  close: {
    position: 'absolute',
    top: 10,
    right: 20,
  },
  title: {
    marginBottom: 20,
  },
  cards: {
    marginTop: 50,
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 10,
    padding: '10px 20px',
    backgroundColor: '#f6f6f6',
    marginBottom: 10,
  },
  digits: {
    marginLeft: 10,
  },
  addCard: {
    position: 'absolute',
    width: 'calc(100% - 40px)',
    bottom: 20,
    left: 20,
    right: 20,
  },
});

const Wallet = (): Node => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const stripeUser = useSelector(userSelectors.stripeUser);
  const currentOrder = useSelector(ordersSelectors.currentOrder);

  const order = currentOrder ? currentOrder.get('order') : null;

  // Order is not persisted so for now, if the user reloads his browser on this page we redirect to the menu page that reloads the order
  useEffect(() => {
    if (!order) history.push('/');
  });

  return (
    <Container className={classes.container}>
      <Box className={classes.close} onClick={history.goBack}>
        <Typography variant="h4" className={classes.closeButton}>
          x
        </Typography>
      </Box>
      <Box className={classes.cards}>
        <Typography variant={'h5'} className={classes.title}>
          {t('containers.Wallet.wallet')}
        </Typography>
        {stripeUser &&
          stripeUser.sources.data.map((card) => (
            <Box key={card.id} className={classes.cardContainer}>
              {card.brand === 'Visa' && <Icon svg={visa} />}
              {card.brand === 'Mastercard' && <Icon svg={mastercard} />}
              <Box className={classes.digits}>
                <Typography>{`**** ${card.last4}`}</Typography>
              </Box>
            </Box>
          ))}
      </Box>
      <RoundButton
        color="primary"
        fullWidth
        className={classes.addCard}
        onClick={() => history.push('add-card')}
      >
        {t('containers.Wallet.addCard')}
      </RoundButton>
    </Container>
  );
};

export default Wallet;
