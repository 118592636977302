import {
  Box,
  CardMedia,
  CircularProgress,
  Container,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { type Node, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { fetchOrders } from '../actions/order';
import RoundButton from '../components/RoundButton';
import { selectors as menuSelectors } from '../reducers/menu';
import { selectors as orderSelectors } from '../reducers/order';
import { selectors as userSelectors } from '../reducers/user';
import calculateTotalPrice from '../utils/calculateTotalPrice';
import computeTableOrderState from '../utils/computeTableOrderState';
import formatMoney from '../utils/formatMoney';

const useStyles = makeStyles({
  container: {
    maxHeight: '100%',
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    position: 'relative',
    borderRadius: 20,
    margin: 20,
    marginTop: 0,
    width: 'calc(100% - 40px)',
    height: 250,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '10px 10px 243px -44px rgba(0,0,0,0.37)',
  },
  header: {
    padding: 20,
    borderBottom: '1px solid lightgrey',
    marginBottom: 20,
  },
  coverPhoto: {
    height: 100,
  },
  nameDateContainer: {
    marginTop: -40,
    marginLeft: 20,
  },
  cardHeader: {
    height: 100,
    flexShrink: 0,
  },
  name: {
    fontWeight: 'bold',
    fontSize: 20,
    color: 'white',
  },
  content: {
    position: 'relative',
    height: 150,
    flexShrink: 0,
    backgroundColor: 'white',
  },
  orderDetails: {
    display: 'flex',
    padding: 10,
  },
  orderId: {
    color: 'lightgrey',
    flexGrow: 1,
  },
  orderPrice: {},
  bold: {
    fontWeight: 'bold',
  },
  orderStatus: {
    padding: 10,
  },
  orderButton: {
    padding: 10,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  small: {
    fontSize: 12,
  },
  receiptButton: {
    border: '1px solid black',
  },
  close: {
    position: 'absolute',
    top: 10,
    right: 20,
  },
  closeButton: {
    color: 'black',
  },
});

// TODO : We're assuming here that only one order can be not paid at once, and it's the currentOrder
const Orders = (): Node => {
  const user = useSelector(userSelectors.user);
  const orders = useSelector(orderSelectors.orders);
  const restaurant = useSelector(menuSelectors.restaurant);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const locale = restaurant.get('country');
  const currency = restaurant.get('currency');

  useEffect(() => {
    dispatch(fetchOrders(user.id));
  }, [user]);

  const getPrice = (order) => {
    const { price } = calculateTotalPrice(order, order.get('restaurant'));
    return price;
  };

  const renderOrder = (order) => (
    <Box className={classes.card} key={order.id}>
      <Box className={classes.cardHeader}>
        <CardMedia
          className={classes.coverPhoto}
          image={order.get('restaurant').get('coverPhoto').url()}
          title="Menu Cover Photo"
        />
        <Box className={classes.nameDateContainer}>
          <Typography className={classes.name}>
            {order.get('restaurant').get('name')}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.content}>
        <Box className={classes.orderDetails}>
          <Box className={classes.orderId}>
            <Typography className={classes.small}>{`#${order.id}`}</Typography>
          </Box>
          <Box className={classes.orderPrice}>
            <Typography
              className={classNames(classes.small, classes.bold)}
            >{`Total ${formatMoney(
              getPrice(order),
              locale,
              currency,
            )}`}</Typography>
          </Box>
        </Box>
        <Box className={classes.orderStatus}>
          <Typography variant="h5" color="primary">
            {computeTableOrderState(order.get('tableOrder'))}
          </Typography>
        </Box>
        <Box className={classes.orderButton}>
          {order.get('paid') === false && (
            <RoundButton
              color="primary"
              fullWidth
              onClick={() => history.push(`/orders/${order.id}`)}
            >
              {t('containers.Orders.viewOrder')}
            </RoundButton>
          )}
          {order.get('paid') === true && (
            <RoundButton
              className={classes.receiptButton}
              color="secondary"
              fullWidth
              onClick={() => history.push(`/orders/${order.id}`)}
            >
              {t('containers.Orders.viewReceipt')}
            </RoundButton>
          )}
        </Box>
      </Box>
    </Box>
  );

  const renderOrders = (orders) => {
    const sortableOrders = [...orders]; // Have to make a copy to be able to sort
    sortableOrders.sort((a, b) => b.updatedAt - a.updatedAt);
    return sortableOrders.map((order) => renderOrder(order));
  };

  return (
    <>
      {!orders ? (
        <Container className={classes.loadingContainer}>
          <CircularProgress />
        </Container>
      ) : (
        <Container className={classes.container}>
          <Box className={classes.header}>
            <Typography>{user.get('name')}</Typography>
          </Box>
          {renderOrders(orders)}
          <Box className={classes.close} onClick={() => history.push('/')}>
            <Typography variant="h4" className={classes.closeButton}>
              x
            </Typography>
          </Box>
        </Container>
      )}
    </>
  );
};

export default Orders;
