import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import { createTransform } from 'redux-persist';
import { createLogger } from 'redux-logger';

const SetTransformRestaurant = createTransform(
  null,
  (outboundState) => ({
    ...outboundState,
    tableOrder: null,
    restaurant: null,
    error: null,
    tableOrderError: null,
    isLoading: false,
    tableOrderIsLoading: false,
    tableOrderSocketOpened: false,
  }),
  { whitelist: ['restaurant'] },
);

const persistConfig = {
  key: 'root',
  storage,
  transforms: [SetTransformRestaurant],
  whitelist: ['restaurant'],
};

const logger = createLogger({
  predicate: () => false, // Activate this line to hide redux logger logs
});

const persistedReducers = persistReducer(persistConfig, reducers);

const sagaMiddleware = createSagaMiddleware();

const middlewares =
  process.env.NODE_ENV === 'production'
    ? applyMiddleware(sagaMiddleware)
    : applyMiddleware(sagaMiddleware, logger);

const store = createStore(persistedReducers, middlewares);
const persistor = persistStore(store);
// persistor.purge();

export { store, persistor, sagaMiddleware };
