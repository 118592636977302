// @flow
import React, { type Node } from 'react';
import { useField } from 'formik';
import { Typography } from '@material-ui/core';
import TextInput from './TextInput';

const TextInputFormik = ({
  style,
  textStyle,
  className,
  ...rest
}: Object): Node => {
  const [field, meta] = useField(rest.name);

  return (
    <div style={style} className={className}>
      <TextInput {...field} {...rest} style={textStyle} />
      <Typography variant="caption" color="error" style={{ marginTop: '4px' }}>
        {meta.touched && meta.error}
      </Typography>
    </div>
  );
};

export default TextInputFormik;
