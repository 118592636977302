// @flow
export const ACTIONS = {
  REQUEST_VERSION: 'version/requestVersion',
  SUCCESS_VERSION: 'version/successVersion',
  ERROR_VERSION: 'version/errorVersion',
  OPEN_VERSION_SOCKET: 'version/openVersionSocket',
  VERSION_SOCKET_LOADING: 'version/versionSocketLoading',
  VERSION_SOCKET_OPENED: 'version/versionSocketOpened',
  IS_ONLINE: 'version/isOnline',
};

export const fetchVersion = (): Object => ({
  type: ACTIONS.REQUEST_VERSION,
});

export const fetchVersionSuccess = (version: Object): Object => ({
  type: ACTIONS.SUCCESS_VERSION,
  payload: { version },
});

export const fetchVersionError = (error: Object): Object => ({
  type: ACTIONS.ERROR_VERSION,
  payload: { error },
});

export const openVersionSocket = (): Object => ({
  type: ACTIONS.OPEN_VERSION_SOCKET,
});

export const markVersionSocketLoading = (): Object => ({
  type: ACTIONS.VERSION_SOCKET_LOADING,
});

export const markVersionSocketOpened = (): Object => ({
  type: ACTIONS.VERSION_SOCKET_OPENED,
});

export const setIsOnline = (status: boolean): Object => ({
  type: ACTIONS.IS_ONLINE,
  payload: status,
});
