// @flow
export const ACTIONS = {
  REQUEST_CONFIG: 'menu/requestConfig',
  SUCCESS_CONFIG: 'menu/successConfig',
  ERROR_CONFIG: 'menu/errorConfig',
};

export const fetchConfig = (): Object => ({
  type: ACTIONS.REQUEST_CONFIG,
});

export const fetchConfigSuccess = (config: Object): Object => ({
  type: ACTIONS.SUCCESS_CONFIG,
  payload: { config },
});

export const fetchConfigError = (error: Object): Object => ({
  type: ACTIONS.ERROR_CONFIG,
  payload: { error },
});
