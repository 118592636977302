// @flow
import {
  Box,
  CircularProgress,
  Container,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as classNames from 'classnames';
import * as Parse from 'parse';
import React, { type Node, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PayPalButton } from 'react-paypal-button-v2';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { callWaiter } from '../actions/menu';
import { payAllRemainingOrders, payOrder } from '../actions/order';
import checkmarkGrey from '../icons/checkmark-grey.svg';
import checkmark from '../icons/checkmark.svg';
import edit from '../icons/edit.svg';
import { selectors as orderSelectors } from '../reducers/order';
import calculateTotalGroupPrice from '../utils/calculateTotalGroupPrice';
import formatMoney from '../utils/formatMoney';
import Icon from './Icon';
import PopupDialog from './PopupDialog';
import RoundButton from './RoundButton';

type Props = {
  price: number,
  refundsTotal: number,
  tax: number,
  tip: number,
  total: number,
  paid: boolean,
  orderObject: Object,
  paymentMethodSelected?: Function,
  onClickTip?: Function,
  user: Object,
  isCurrentUser: boolean,
  order: Object,
  tableNumber: string,
  restaurant: Object,
  tableOrder?: Object,
};

const useStyles = makeStyles({
  promoIcon: {
    padding: 20,
  },
  promoCodeField: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
  },
  row: {
    display: 'flex',
    marginBottom: 15,
  },
  promoMargin: {
    marginTop: 30,
    marginBottom: 30,
  },
  rowTitle: {
    display: 'flex',
    flexGrow: 1,
  },
  rowValue: {
    display: 'flex',
    fontWeight: 'bold',
  },
  button: {
    width: '100%',
    marginTop: 20,
    fontWeight: 'bold',
  },
  title: {
    marginBottom: 20,
  },
  paymentTitle: {
    marginTop: 30,
  },
  info: {
    color: '#a8a8a8',
    fontStyle: 'italic',
    marginTop: 20,
  },
  modalTitle: {
    padding: 20,
    fontWeight: 'bold',
  },
  modalContent: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  paypalModalContent: {
    padding: 20,
    display: 'flex',
  },
  modalButtons: {
    position: 'absolute',
    bottom: 20,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalCancel: {
    marginRight: 20,
  },
  modalConfirm: {
    color: 'white',
    margin: 20,
  },
});

const CostSection = ({
  price,
  refundsTotal,
  tax,
  tip,
  total,
  paid,
  orderObject,
  paymentMethodSelected,
  onClickTip,
  user,
  isCurrentUser,
  order,
  tableNumber,
  restaurant,
  tableOrder,
}: Props): Node => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const paymentInProcess = useSelector(orderSelectors.paymentInProcess);

  const [payAllModalIsOpen, setPayAllModalIsOpen] = useState(false);
  const [paypalPayModalIsOpen, setPaypalPayModalIsOpen] = useState(null);
  const [paypalIsLoading, setPaypalIsLoading] = useState(false);

  const renderIcon = (svg, small) => <Icon svg={svg} small={small} />;
  const noPendingItems = !order.find((item) => item.state === 'pending');
  const payByCard = orderObject.get('paymentMode') === 'card';
  const payCash = orderObject.get('paymentMode') === 'cash';
  const locale = restaurant.get('country');
  const currency = restaurant.get('currency');

  const renderPayRemainingBillsButton = (tableOrder) => {
    const orders = tableOrder.get('orders');
    const unpaidOrders = orders.filter((order) => !order.get('paid'));
    if (unpaidOrders.length <= 1) {
      return null;
    }
    return paymentInProcess ? (
      <></>
    ) : (
      <RoundButton
        className={classes.button}
        fullWidth
        color="primary"
        disabled={paymentInProcess}
        onClick={() => {
          setPayAllModalIsOpen(true);
        }}
      >
        {t('containers.CostSection.payRemaining', {
          remaining: unpaidOrders.length,
        })}
      </RoundButton>
    );
  };

  const checkoutFawry = async (orderId, tableOrderId) => {
    const configuration = {
      locale: 'en', //default en
      // eslint-disable-next-line no-undef
      mode: DISPLAY_MODE.POPUP, //required, allowed values [POPUP, INSIDE_PAGE, SIDE_PAGE]
      onSuccess: async () => {
        Parse.Cloud.run('captureFawryOrder', { orderId, tableOrderId });
      },
      onFailure: () => {
        console.log('failure');
      },
    };
    const chargeRequest = await Parse.Cloud.run('createFawryOrder', {
      orderId,
      tableOrderId,
    });

    if (!chargeRequest) {
      console.log('undefined charge request');
      return;
    }
    // eslint-disable-next-line no-undef
    FawryPay.checkout(chargeRequest, configuration);
  };

  return (
    <>
      <Box className={classes.row}>
        <Typography className={classes.rowTitle}>
          {t('containers.CostSection.subtotal')}
        </Typography>
        <Typography className={classes.rowValue}>
          {formatMoney(price, locale, currency)}
        </Typography>
      </Box>
      {refundsTotal > 0 && (
        <Box className={classes.row}>
          <Typography className={classes.rowTitle}>
            {t('containers.CostSection.totalRefunds')}
          </Typography>
          <Typography className={classes.rowValue}>
            {formatMoney(refundsTotal, locale, currency)}
          </Typography>
        </Box>
      )}
      <Box className={classes.row}>
        <Typography className={classes.rowTitle}>
          {t('containers.CostSection.tax')}
        </Typography>
        <Typography className={classes.rowValue}>
          {formatMoney(tax, locale, currency)}
        </Typography>
      </Box>
      <Box
        className={classes.row}
        onClick={() => {
          if (!paid && onClickTip) onClickTip();
        }}
      >
        <Box className={classes.rowTitle}>
          <Typography>
            {t('containers.CostSection.tip', {
              tip: orderObject.get('tip') * 100,
            })}
          </Typography>
          {!paid ? renderIcon(edit, true) : null}
        </Box>
        <Typography className={classes.rowValue}>
          {formatMoney(tip, locale, currency)}
        </Typography>
      </Box>
      <Box className={classes.row}>
        <Typography className={classes.rowTitle}>
          {t('containers.CostSection.total')}
        </Typography>
        <Typography className={classes.rowValue}>
          {formatMoney(total, locale, currency)}
        </Typography>
      </Box>
      {tableOrder &&
        (tableOrder.get('state') === 'completed' || (paid && payCash)) && (
          <Box>
            {(noPendingItems || (paid && !isCurrentUser)) && (
              <Typography
                variant="h6"
                className={classNames(classes.title, classes.paymentTitle)}
              >
                {!paid
                  ? t('containers.CostSection.paymentMethod')
                  : t('containers.CostSection.paidWith')}
              </Typography>
            )}
            {((!paid && noPendingItems) || (paid && payByCard)) && (
              <Box
                className={classes.row}
                onClick={() => {
                  if (!paid && paymentMethodSelected)
                    paymentMethodSelected('card', orderObject);
                }}
              >
                <Typography className={classes.rowTitle}>
                  Credit card
                </Typography>
                <Box className={classes.rowValue}>
                  {orderObject.get('paymentMode') === 'card'
                    ? renderIcon(checkmark)
                    : renderIcon(checkmarkGrey)}
                </Box>
              </Box>
            )}
            {((!paid && noPendingItems) || (paid && payCash)) && (
              <Box
                className={classes.row}
                onClick={() => {
                  if (!paid && paymentMethodSelected)
                    paymentMethodSelected('cash', orderObject);
                }}
              >
                <Typography className={classes.rowTitle}>
                  {t('common.cash')}
                </Typography>
                <Box className={classes.rowValue}>
                  {orderObject.get('paymentMode') === 'cash'
                    ? renderIcon(checkmark)
                    : renderIcon(checkmarkGrey)}
                </Box>
              </Box>
            )}
            {!paid && noPendingItems && (
              <>
                {orderObject.get('paymentMode') === 'card' ? (
                  <>
                    <RoundButton
                      className={classes.button}
                      fullWidth
                      color="primary"
                      disabled={paymentInProcess}
                      onClick={() => {
                        if (restaurant.get('paymentSystem') === 'paypal') {
                          setPaypalPayModalIsOpen({ orderId: orderObject.id });
                        } else if (
                          restaurant.get('paymentSystem') === 'fawrypay'
                        ) {
                          checkoutFawry(orderObject.id);
                        } else {
                          dispatch(payOrder(user, orderObject.id, history));
                        }
                      }}
                    >
                      {paymentInProcess ? (
                        <CircularProgress size={24} />
                      ) : isCurrentUser ? (
                        t('containers.CostSection.payMyBill')
                      ) : (
                        t(
                          'containers.CostSection.payUserBill',
                          orderObject.get('user').get('name'),
                        )
                      )}
                    </RoundButton>
                    {tableOrder && renderPayRemainingBillsButton(tableOrder)}
                  </>
                ) : (
                  <>
                    <RoundButton
                      className={classes.button}
                      fullWidth
                      color="primary"
                      onClick={() => {
                        dispatch(
                          callWaiter(
                            user,
                            tableNumber,
                            restaurant,
                            'waiterPayment',
                          ),
                        );
                      }}
                    >
                      {t('containers.CostSection.callWaiter')}
                    </RoundButton>
                  </>
                )}
              </>
            )}
          </Box>
        )}
      {tableOrder &&
        tableOrder.get('state') !== 'completed' &&
        tableOrder.get('state') !== 'closed' &&
        !orderObject.get('paid') && (
          <Box>
            <Typography className={classes.info}>
              {t('containers.CostSection.pay')}
            </Typography>
          </Box>
        )}
      {tableOrder && (
        <PopupDialog isOpen={payAllModalIsOpen} height={200}>
          <Typography className={classes.modalTitle}>Are you sure ?</Typography>
          <Typography className={classes.modalContent}>
            {t('containers.CostSection.charge', {
              amount: formatMoney(
                calculateTotalGroupPrice(tableOrder, restaurant),
                locale,
                currency,
              ),
            })}
          </Typography>
          <Box className={classes.modalButtons}>
            <RoundButton
              className={classes.modalCancel}
              onClick={() => setPayAllModalIsOpen(false)}
            >
              {t('common.cancel')}
            </RoundButton>
            <RoundButton
              className={classes.modalConfirm}
              color="primary"
              onClick={() => {
                setPayAllModalIsOpen(false);
                if (restaurant.get('paymentSystem') === 'paypal') {
                  setPaypalPayModalIsOpen({ tableOrderId: tableOrder.id });
                } else if (restaurant.get('paymentSystem') === 'fawrypay') {
                  checkoutFawry(undefined, tableOrder.id);
                } else {
                  dispatch(payAllRemainingOrders(user, tableOrder.id, history));
                }
              }}
            >
              {t('common.ok')}
            </RoundButton>
          </Box>
        </PopupDialog>
      )}
      {tableOrder && (
        <PopupDialog
          isOpen={!!paypalPayModalIsOpen}
          height={300}
          onRequestClose={
            !paypalIsLoading ? () => setPaypalPayModalIsOpen(null) : undefined
          }
        >
          <Container className={classes.paypalModalContent}>
            {paypalIsLoading ? (
              <div
                style={{ display: 'flex', justifyContent: 'center', flex: 1 }}
              >
                <CircularProgress
                  size={24}
                  color="primary"
                  style={{ alignSelf: 'center' }}
                />
              </div>
            ) : (
              <PayPalButton
                options={{
                  currency: restaurant.get('currency').toUpperCase(),
                  clientId:
                    'AeioV1eCggQb_uGkHxsEp7hpAuu-A1VWg63yWl2xkuJOJwGPaF4YYbbm8Suydg-cVmh74Ufq2DJxG0cH',
                }}
                createOrder={async () => {
                  try {
                    return await Parse.Cloud.run(
                      'createPaypalOrder',
                      paypalPayModalIsOpen,
                    );
                  } catch (error) {
                    console.log(error);
                    setPaypalPayModalIsOpen(null);
                  }
                }}
                onApprove={async () => {
                  try {
                    setPaypalIsLoading(true);
                    await Parse.Cloud.run(
                      'capturePaypalOrder',
                      paypalPayModalIsOpen,
                    );
                  } catch (error) {
                    console.log(error);
                  } finally {
                    setPaypalPayModalIsOpen(null);
                    setPaypalIsLoading(false);
                  }
                }}
              />
            )}
          </Container>
        </PopupDialog>
      )}
    </>
  );
};

export default CostSection;
