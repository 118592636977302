// @flow
import { Box, Container, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { type Node, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { switchRestaurant } from '../actions/menu';
import { logout, updateUserName } from '../actions/user';
import Icon from '../components/Icon';
import RoundButton from '../components/RoundButton';
import Terms from '../icons/balance.svg';
import Contact from '../icons/contact.svg';
import Instagram from '../icons/instagram.svg';
import LogoutIcon from '../icons/logout.svg';
import Orders from '../icons/orders.svg';
import Profile from '../icons/profile-black.svg';
import Wallet from '../icons/wallet.svg';
import { selectors as configSelectors } from '../reducers/config';
import { selectors as ordersSelectors } from '../reducers/order';
import { selectors as userSelectors } from '../reducers/user';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    padding: 20,
  },
  close: {
    position: 'absolute',
    top: 10,
    right: 20,
  },
  closeButton: {
    color: 'black',
  },
  title: {
    marginTop: 20,
    marginBottom: 20,
  },
  row: {
    display: 'flex',
    marginBottom: 15,
  },
  rowTitle: {
    marginLeft: 10,
    display: 'flex',
    flexGrow: 1,
    color: 'grey',
  },
  rowValue: {
    display: 'flex',
    fontWeight: 'bold',
  },
  modalContainer: {
    width: '100%',
    height: '100%',
  },
  closeModal: {
    position: 'absolute',
    top: 10,
    right: 20,
  },
  name: {
    padding: 20,
    width: '100%',
    flexGrow: 1,
    marginTop: 60,
  },
  nameField: {
    width: 'calc(100% - 40px)',
  },
  modalButtons: {
    position: 'absolute',
    bottom: 20,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalCancel: {
    marginRight: 20,
  },
  modalConfirm: {
    color: 'white',
    margin: 20,
  },
});

const modalStyles = {
  content: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: 0,
    border: 0,
  },
};

const Settings = (): Node => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const currentOrder = useSelector(ordersSelectors.currentOrder);
  const user = useSelector(userSelectors.user);
  const config = useSelector(configSelectors.config);

  const [namePopupOpened, setNamePopupOpened] = useState(false);
  const [name, setName] = useState(user.get('name'));

  const order = currentOrder ? currentOrder.get('order') : null;

  // Order is not persisted so for now, if the user reloads his browser on this page we redirect to the menu page that reloads the order
  useEffect(() => {
    if (!order) history.push('/');
  });

  const renderNamePopup = () => (
    <Modal isOpen={namePopupOpened} style={modalStyles}>
      <Box className={classes.modalContainer}>
        <Box className={classes.name}>
          <TextField
            id="outlined-basic"
            label={t('containers.Settings.name')}
            variant="outlined"
            className={classes.nameField}
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
        </Box>
        <Box className={classes.modalButtons}>
          <RoundButton
            className={classes.modalCancel}
            onClick={() => setNamePopupOpened(false)}
          >
            {t('common.cancel')}
          </RoundButton>
          <RoundButton
            className={classes.modalConfirmSmall}
            color="primary"
            onClick={() => {
              setNamePopupOpened(false);
              dispatch(updateUserName(name, user));
            }}
          >
            {t('common.confirm')}
          </RoundButton>
        </Box>
      </Box>
    </Modal>
  );

  return (
    <>
      <Container className={classes.container}>
        <Box className={classes.close} onClick={history.goBack}>
          <Typography variant="h4">x</Typography>
        </Box>
        <Typography variant="h6" className={classes.title}>
          {user.get('name')}
        </Typography>
        {currentOrder && currentOrder.get('paid') && (
          <Box
            className={classes.row}
            onClick={() => {
              dispatch(switchRestaurant());
            }}
          >
            <Icon svg={Orders} small />
            <Typography className={classes.rowTitle}>
              {t('containers.Settings.switchRestaurant')}
            </Typography>
          </Box>
        )}
        <Box className={classes.row} onClick={() => history.push('wallet')}>
          <Icon svg={Wallet} small />
          <Typography className={classes.rowTitle}>
            {t('containers.Settings.wallet')}
          </Typography>
        </Box>
        <Box className={classes.row} onClick={() => history.push('orders')}>
          <Icon svg={Orders} small />
          <Typography className={classes.rowTitle}>
            {t('containers.Settings.orders')}
          </Typography>
        </Box>
        <Box className={classes.row} onClick={() => setNamePopupOpened(true)}>
          <Icon svg={Profile} small />
          <Typography className={classes.rowTitle}>
            {t('containers.Settings.profile')}
          </Typography>
        </Box>
        <Box className={classes.row} onClick={() => dispatch(logout(history))}>
          <Icon svg={LogoutIcon} small />
          <Typography className={classes.rowTitle}>
            {t('containers.Settings.logout')}
          </Typography>
        </Box>
        <Typography variant="h6" className={classes.title}>
          {t('containers.Settings.aboutUs')}
        </Typography>
        <Box
          className={classes.row}
          onClick={() => window.open(config.get('homepage'))}
        >
          <Icon svg={Contact} small />
          <Typography className={classes.rowTitle}>
            {t('containers.Settings.contact')}
          </Typography>
        </Box>
        <Box
          className={classes.row}
          onClick={() => window.open(config.get('instagram'))}
        >
          <Icon svg={Instagram} small />
          <Typography className={classes.rowTitle}>
            {t('containers.Settings.instagram')}
          </Typography>
        </Box>
        <Box
          className={classes.row}
          onClick={() => window.open(config.get('terms'))}
        >
          <Icon svg={Terms} small />
          <Typography className={classes.rowTitle}>
            {t('containers.Settings.terms')}
          </Typography>
        </Box>
        {renderNamePopup()}
      </Container>
    </>
  );
};

export default Settings;
