// @flow
import { Box, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { type Node } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { addPaymentMethod } from '../actions/user';
import RoundButton from '../components/RoundButton';
import creditCard from '../icons/credit-card.svg';
import stripe from '../icons/stripe.svg';
import { selectors as userSelectors } from '../reducers/user';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    padding: 20,
  },
  close: {
    position: 'absolute',
    top: 10,
    right: 20,
  },
  card: {
    width: '70%',
    margin: '50px 15%',
    height: 'auto',
  },
  stripe: {
    width: '50%',
    margin: '0px 25% 20px 25%',
    height: 'auto',
  },
  stripeContent: {
    padding: 10,
    borderRadius: 30,
    border: '1px solid lightgrey',
    marginBottom: 20,
  },
});

const CardForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.user);
  const classes = useStyles();
  const history = useHistory();

  const [errorToDisplay, setErrorToDisplay] = React.useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { error, token } = await stripe.createToken(
      elements.getElement(CardElement),
    );
    if (error) {
      setErrorToDisplay(error);
    } else if (token) {
      dispatch(addPaymentMethod(token.id, user.id, user, history));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement className={classes.stripeContent} />
      {errorToDisplay && (
        <Box>
          <Typography color="primary">{errorToDisplay}</Typography>
        </Box>
      )}
      <RoundButton type="submit" color="primary" fullWidth disabled={!stripe}>
        Add card
      </RoundButton>
    </form>
  );
};

const stripeKeyTest =
  'pk_test_51H83r4EjxIi8vWYYN8Oek4zH4zQsU3UZIbbCOEj2H781SLqIep2gg1Kn3RV3CgnxYGMRyRaLlUGRbU7VTZ3tMqAK00DX4wwJQz';

// const stripeKeyLive =
//   'pk_live_51H83r4EjxIi8vWYYiFsvpI9lbUUdmcNGdOLem5pRERsFAk2bC4OdJRzX4HigThl5hmWMQSyVXxnNUmY5KReW2uv800Art39k2R';

const stripePromise = loadStripe(stripeKeyTest);

const AddCard = (): Node => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Elements stripe={stripePromise}>
      <Container className={classes.container}>
        <Box className={classes.close} onClick={history.goBack}>
          <Typography variant="h4">x</Typography>
        </Box>
        <img className={classes.card} src={creditCard} alt="card" />
        <img className={classes.stripe} src={stripe} alt="powered by Stripe" />
        <CardForm />
      </Container>
    </Elements>
  );
};

export default AddCard;
