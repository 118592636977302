import { take } from 'redux-saga/effects';
import { ACTIONS } from '../actions/menu';
import { ACTIONS as USER_ACTIONS } from '../actions/user';

export default function* watchCloseChannelMessage(channel, name) {
  switch (name) {
    case 'TableOrderSocket':
    case 'AllOrdersFromTableOrderSocket':
    case 'RestaurantSocket':
      yield take([
        ACTIONS.TABLE_ORDER_CLOSED,
        ACTIONS.SWITCH_RESTAURANT,
        USER_ACTIONS.LOGOUT,
      ]);
      channel.close();
      break;
    case 'VersionSocket':
    case 'AllUserOrdersSocket':
    default:
      yield take(USER_ACTIONS.LOGOUT);
      channel.close();
      break;
  }
}
