// @flow
import { Box } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';
import QrReader from 'react-qr-reader';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  switchRestaurant,
  restaurantIdAndTableNumberScanned,
} from '../actions/menu';
import PopupDialog from './PopupDialog';
import RoundButton from './RoundButton';
import logo from '../images/logo.png';
import { selectors as menuSelectors } from '../reducers/menu';
import { selectors as userSelectors } from '../reducers/user';

import './styles.css';

const useStyles = makeStyles({
  logoContainer: {
    width: '100%',
    height: 160,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: 125,
    height: 125,
  },
  textContainer: {
    padding: 20,
    paddingTop: 0,
    textAlign: 'center',
  },
  text: {
    fontSize: 14,
  },
  QrCodeContainer: {
    padding: 30,
    paddingBottom: 0,
  },
  qrReader: {},
  modalTitle: {
    padding: 20,
    fontWeight: 'bold',
  },
  modalContent: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  modalButtons: {
    position: 'absolute',
    bottom: 20,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalConfirm: {
    color: 'white',
  },
  ordersButton: {
    position: 'absolute',
    width: 'calc(100% - 40px)',
    bottom: 20,
    left: 20,
    right: 20,
  },
});

const QrCode = (): Node => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const tableOrderError = useSelector(menuSelectors.tableOrderError);
  const user = useSelector(userSelectors.user);
  const history = useHistory();
  const { t } = useTranslation();

  const handleScan = (data) => {
    if (data) {
      const string = data.substr(data.lastIndexOf('/') + 1);
      const [restaurantId, tableNumber] = string.split('-');
      dispatch(restaurantIdAndTableNumberScanned(restaurantId, tableNumber));
    }
  };

  // TODO: handle bad QR code
  const handleError = (err) => {
    console.error('QR code error', err);
  };
  return (
    <>
      <Box className={classes.logoContainer}>
        <CardMedia className={classes.logo} image={logo} />
      </Box>
      <Box className={classes.textContainer}>
        <Typography className={classes.text}>
          {t('containers.QrCode.scan')}
        </Typography>
      </Box>
      {!tableOrderError && (
        <Box className={classes.QrCodeContainer}>
          <QrReader
            delay={300}
            onError={handleError}
            onScan={handleScan}
            className="qr-reader"
          />
        </Box>
      )}
      <PopupDialog isOpen={!!tableOrderError} height={200}>
        <>
          <Typography className={classes.modalTitle}>
            {t('containers.QrCode.waiter')}
          </Typography>
          <Typography className={classes.modalContent}>
            {t('containers.QrCode.waiterDetails')}
          </Typography>
          <Box className={classes.modalButtons}>
            <RoundButton
              className={classes.modalConfirm}
              color="primary"
              onClick={() => {
                dispatch(switchRestaurant());
              }}
            >
              {t('common.ok')}
            </RoundButton>
          </Box>
        </>
      </PopupDialog>
      {user && (
        <RoundButton
          fullWidth
          className={classes.ordersButton}
          color="primary"
          onClick={() => history.push('/orders')}
        >
          {t('containers.QrCode.orders')}
        </RoundButton>
      )}
    </>
  );
};

export default QrCode;
