import React, { useEffect } from 'react';
import * as Parse from 'parse';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import { PayPalButton } from 'react-paypal-button-v2';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { selectors as menuSelectors } from '../reducers/menu';
import { selectors as orderSelectors } from '../reducers/order';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    padding: 20,
  },
  close: {
    alignSelf: 'flex-end',
  },
});

const Paypal = () => {
  const classes = useStyles();
  const history = useHistory();
  const { orderId } = useParams();

  const restaurant = useSelector(menuSelectors.restaurant);
  const currentOrder = useSelector(orderSelectors.currentOrder);

  useEffect(() => {
    if (currentOrder?.get('paid')) {
      history.goBack();
    }
  }, [currentOrder]);

  return (
    <Container className={classes.container}>
      <Box className={classes.close} onClick={history.goBack}>
        <Typography variant="h4">x</Typography>
      </Box>
      {restaurant && (
        <PayPalButton
          options={{
            currency: restaurant.get('currency').toUpperCase(),
            clientId:
              'AeioV1eCggQb_uGkHxsEp7hpAuu-A1VWg63yWl2xkuJOJwGPaF4YYbbm8Suydg-cVmh74Ufq2DJxG0cH',
          }}
          createOrder={() =>
            Parse.Cloud.run('createPaypalOrder', {
              orderId,
            })
          }
          onApprove={async () => {
            const res = await Parse.Cloud.run('capturePaypalOrder', {
              orderId,
            });
            if (res) {
              // dispatch(fetchOrders());
            }
          }}
          // onSuccess={(details) => console.log('onSuccess', details)}
        />
      )}
    </Container>
  );
};

export default Paypal;
