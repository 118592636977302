import type { Node } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as menuSelectors } from '../reducers/menu';
import PopupDialog from './PopupDialog';
import { Box, Typography } from '@material-ui/core';
import RoundButton from './RoundButton';
import { setWaiterCallSent } from '../actions/menu';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  modalTitle: {
    padding: 20,
    fontWeight: 'bold',
  },
  modalContent: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  modalButtons: {
    position: 'absolute',
    bottom: 20,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalCancel: {
    marginRight: 20,
  },
  modalConfirm: {
    color: 'white',
  },
});

const WaiterCallPopup = (): Node => {
  const waiterCallSent = useSelector(menuSelectors.waiterCallSent);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <PopupDialog isOpen={waiterCallSent} height={190}>
      <Typography className={classes.modalTitle}>
        {t('components.WaiterCallPopup.title')}
      </Typography>
      <Typography className={classes.modalContent}>
        {t('components.WaiterCallPopup.content')}
      </Typography>
      <Box className={classes.modalButtons}>
        <RoundButton
          className={classes.modalConfirm}
          color="primary"
          onClick={() => dispatch(setWaiterCallSent(false))}
        >
          {t('common.close')}
        </RoundButton>
      </Box>
    </PopupDialog>
  );
};

export default WaiterCallPopup;
