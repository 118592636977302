import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#151515',
    },
    secondary: {
      main: '#FFFFFF',
    },
    text: {
      main: '#000',
    },
  },
  typography: {
    h5: {
      fontSize: 'larger',
      fontWeight: 'bolder',
    },
    h6: {
      fontSize: 'medium',
      fontWeight: 'bold',
    },
  },
  overrides: {
    MuiContainer: {
      root: { paddingLeft: 0, paddingRight: 0, height: '100%' },
    },
  },
});

export default theme;
